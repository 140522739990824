import React, { useState } from 'react';
import PageHeader from './Component/PageHeader';
import Customwebdesignteamcollaboration from './Images/CustomWebDesign.webp';
import CompanyName from './Component/CompanyName';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WhyChoose from './Component/WhyChoose';
// import CustomBenefits from './Component/CustomBenefits';
import { Helmet } from 'react-helmet-async';
import { Link } from '@mui/material';
import { Button, ThemeProvider, createTheme, Dialog, DialogContent } from '@mui/material';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import QuoteCalculator from './Component/Quote';
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableContainer
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const theme = createTheme({
    palette: {
        primary: {
            main: "#99aab5", // custom primary color
        },
        secondary: {
            main: "#C13E39", // custom secondary color
        },
    },
});

const currentYear = new Date().getFullYear();

const Company = "Web Design Expressions"


const ChooseCustomWebDesign = {
    service: "Custom Web Design",
    introText: `Looking for a custom web design company that understands the unique needs of small businesses? We specialize in affordable custom website design for small businesses. In ${currentYear}, having a professional online presence is more important than ever. Our custom website designing services transform your vision into a powerful online presence that drives results and sets you apart from competitors. As a leading website design company in the USA, We create distinctive websites customized to your business goals.`,
    benefits: [
        {
            title: "Personalized Design Strategy",
            description: `Unlike template-based solutions, our custom web design services focus on your specific business goals. We craft unique designs that reflect your brand identity, engage your target audience, and drive conversions—all while keeping your budget in mind. Whether you need a custom small business web design or a large-scale enterprise solution, we’ve got you covered.`
        },
        {
            title: "Results-Driven Development",
            description: `Our professional web design services combine stunning visuals with powerful functionality. Every website we build includes SEO-friendly architecture, fast loading speeds, and mobile-first design to ensure maximum visibility and user engagement. As a trusted web design company in the USA, we deliver websites that perform.`
        },
        {
            title: "Competitive Pricing Without Compromise",
            description: `As an affordable website design agency, we deliver premium quality without the premium price tag. Our transparent pricing and flexible packages ensure you get exceptional value and ROI from your investment. Whether you’re a startup or an established business, we offer affordable custom website design for small businesses.`
        },
        {
            title: "Industry-Specific Expertise",
            description: `Every industry has unique requirements. Our custom web design services include specialized features and optimizations for your specific sector, whether you're in healthcare, retail, professional services, or manufacturing. We understand the challenges small businesses face and tailor our solutions to meet your needs.`
        },
        {
            title: "Conversion-Focused Design",
            description: `Your website isn't just a digital brochure—it's your 24/7 sales team. We integrate proven conversion strategies into every custom design, ensuring your website effectively turns visitors into customers. With our custom website design for small businesses, you’ll see measurable results.`
        },
        {
            title: "Future-Proof Technology",
            description: `Our custom web design company stays ahead of digital trends. We build your site using scalable, modern technologies that adapt to changing business needs and growing traffic demands. In ${currentYear}, your website needs to be ready for the future, and we make sure it is.`
        },
        {
            title: "Ongoing Support & Optimization",
            description: `Partner with a custom website design agency that's invested in your long-term success. We provide continuous support, performance monitoring, and optimization to keep your site performing at its peak. From updates to security patches, we’ve got your back.`
        }
    ],
    finalHeader: `Ready to Grow Your Business Online`,
    finalText: `Choosing us means partnering with experts who are passionate about your success. As a leading website design company in the USA, we’re committed to helping businesses like yours succeed online. Whether you’re looking for affordable professional web design or a full-service custom web design for small business, ${Company} is here to help. Let’s build something amazing together!`
};

const CustomdesignProcess = [
    {
        id: 1,
        Process: 'Customized to Your Needs',
        Description: 'A custom-designed website allows you to have complete control over the look, feel, and functionality of your site. From the layout to the features, everything can be personalized to suit your business and your customers’ expectations. Whether you need an e-commerce platform, a portfolio site, or a blog, we ensure that every element is built to enhance user experience and achieve your objectives.'
    },
    {
        id: 2,
        Process: 'Stronger Branding and Identity',
        Description: 'A custom web design ensures that your website stands out from the competition. By aligning your site with your brand’s colors, fonts, imagery, and messaging, you reinforce your business identity and create a consistent experience across all digital touchpoints. Your website will resonate with your target audience, giving you an edge in a crowded marketplace.'
    },
    {
        id: 3,
        Process: 'Scalability and Flexibility',
        Description: 'As your business grows, so too should your website. Custom web design provides the flexibility to add new features, pages, and functionalities as your business evolves. Whether you want to integrate a booking system, add an online store, or include more sophisticated content, a custom website can scale with you. Unlike templates, which can be restrictive, custom web design allows you to adapt your website to future needs.'
    },
    {
        id: 4,
        Process: 'Improved Performance and Speed',
        Description: 'Website performance is a critical factor in retaining visitors. A slow-loading site can frustrate users and lead to high bounce rates. Custom web design prioritizes fast load times and optimized performance, ensuring that your website runs smoothly on all devices. From streamlined coding to optimized images and resources, we make sure your website is built for speed and efficiency.'
    },
    {
        id: 5,
        Process: 'Better Security',
        Description: `When you choose a template or a DIY website builder, you’re relying on a system that many other businesses are using. This increases the likelihood of vulnerabilities that hackers can exploit. A custom-built website offers better security because it’s less predictable and more difficult to breach. We implement robust security measures, including SSL certificates, secure coding practices, and regular updates, to ensure your site is protected from cyber threats.`
    },
    {
        id: 6,
        Process: 'SEO-Friendly',
        Description: `Having a visually stunning website is only half the battle; the other half is ensuring people can find it. At ${Company}, we design custom websites with SEO (Search Engine Optimization) best practices in mind. From clean code to optimized metadata, we make sure your website is built to rank well on search engines. A high-ranking website increases visibility and drives organic traffic, helping you reach more potential customers.`
    }
];


// Custom styled components
const TransparentAccordion = styled(Accordion)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1f2937' : 'transparent',
    color: theme.palette.mode === 'dark' ? '#ffffff' : 'inherit',
}));

const TransparentAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1f2937' : 'transparent',
    color: theme.palette.mode === 'dark' ? '#ffffff' : 'inherit',
}));

const TransparentAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1f2937' : 'transparent',
    color: theme.palette.mode === 'dark' ? '#ffffff' : 'inherit',
}));

const CustomExpandMoreIcon = styled(ExpandMoreIcon)(({ theme }) => ({
    color: theme.palette.mode === 'dark' ? '#F13030' : '#F13030',
}));

const CustomWebDesign = () => {

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };



    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <div>



            <Helmet prioritizeSeoTags>
                <title>Custom Web Design Services, Affordable Website Agency, USA</title>
                <meta data-react-helmet="true" name="description" content="Affordable custom web design by a USA-based agency. Boost your online presence with expert solutions for businesses. Free quote today!" />
                <meta data-react-helmet="true" name="keywords" content="custom web design services, custom website design agency, custom web design company, affordable custom web design, custom web design company USA" />
                <meta data-react-helmet="true" property="og:title" content="Custom Web Design Services, Affordable Website Agency, USA" />
                <meta data-react-helmet="true" property="og:type" content="business" />
                <meta data-react-helmet="true" property="og:url" content="https://webdesignexpressions.net/CustomWebDesign" />
                <meta data-react-helmet="true" property="og:image" content="https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/OGCustomWebDesign.jpg?alt=media&token=1b1eca61-372e-41ee-8e42-3d64536c0c70" />
                <meta data-react-helmet="true" property="og:description" content="Professional custom web design services designed to help businesses stand out and grow their online presence worldwide." />
            </Helmet>


            <PageHeader pageTitle="Custom Web Design Services" />


            <section className='mx-16'>
                <article>

                    <h2 className="text-3xl font-bold pb-8">
                        <span>Transform Your Online Presence with Custom Web Design in </span>{" "}
                        <span>{currentYear}</span>{" "}
                    </h2>

                </article>
            </section>


            <section className="mx-16">
                <p className="">
                    <span>At </span>{" "}
                    <span><CompanyName /></span>{" "}
                    <span>, we specialize in creating stunning, high-performing websites customized to your unique business needs. As a USA-based web design company, we help businesses like yours stand out in the digital world.</span>{" "}
                    <span><Link href="/Portfolio" className="underline" title='View our custom web design portfolio'>View our portfolio</Link>{" "} </span>
                    <span>to see how we've helped businesses like yours succeed.</span>{" "}
                    <span>Whether you're a startup or an established brand, our custom web design services are designed to drive results.</span>{" "}

                </p>
                <p className="my-4">
                    <span>Your website is the cornerstone of your brand's online presence. Often the first touchpoint for potential customers, it serves as a 24/7 ambassador for your business. At </span>{" "}
                    <span><CompanyName /></span>{" "}
                    <span>, we ensure your website is more than just a digital business card—it's a marketing powerhouse that converts visitors into loyal customers. Additionally, we provide robust </span>{" "}
                    <span><Link href="/WebsiteSecurity" className="underline" title='Our professional website security solutions'>website security solutions</Link> </span>{" "}
                    <span>to protect your business from cyber threats and maintain customer trust. As a professional web design company, we combine creativity, technology, and strategy to deliver websites that stand out and perform.</span>{" "}

                </p>
            </section>


            <figure className='my-8'>
                <img className='mx-auto max-w-full h-auto'
                    alt='Team of web designers collaborating on custom website design project using visual planning techniques'

                    src={Customwebdesignteamcollaboration}
                    title='Professional Custom Web Design Team | Affordable Web Design Solutions'
                />
                <figcaption className="text-center text-sm text-gray-600 mt-2">
                    <span>Our team of experts collaborates to deliver stunning custom web designs customized for your business needs.</span>
                </figcaption>
            </figure>

            <section className="mx-16">
                <h2 className="text-3xl font-bold mb-6">Custom Web Design Services for Every Business</h2>
                <p className="my-4">
                    <span>Whether you need a simple website or a complex </span>{" "}
                    <span><Link href="/Ecommerce" className="underline" title='Our professional ecommerce web design solutions'>E-commerce platform</Link></span>{" "}
                    <span>, </span>{" "}
                    <span><CompanyName /> </span>{" "}
                    <span>has you covered. Our custom website builders are experts in creating websites that not only look great but also perform exceptionally well. From affordable web design company solutions to enterprise-level projects, we've got the expertise to deliver.</span>{" "}

                </p>
                <p className="my-4">
                    With our business website design services, we focus on creating custom websites that not only look stunning but also drive measurable results. Your website should be more than just a digital business card; it should be a marketing powerhouse that converts visitors into loyal customers.
                </p>
            </section>


            <section className="mx-16">
                <h2 className="text-3xl font-bold mb-6">How Much Does Custom Website Design Cost?</h2>
                <p className="my-4">
                    <span>The cost of a custom website design varies depending on your requirements. Factors like the number of pages, features, and integrations all play a role. At </span>{" "}
                    <span><CompanyName /></span>{" "}
                    <span>, we offer transparent pricing and flexible packages to suit your budget.</span>{" "}


                </p>

                <div className="lg:w-1/2 mx-auto" role="button">
                    <ThemeProvider theme={theme}>
                        <Button fullWidth variant="contained" id="btnSubmit" color="secondary" style={{ color: 'inherit' }} size="large" title="Request a Quote" onClick={handleOpen}>
                            <RequestQuoteIcon />&nbsp;&nbsp;<b>Request a Quote</b>
                        </Button>
                    </ThemeProvider>

                    <ThemeProvider theme={theme}>
                        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm" color="inherit"
                            elevation="24"
                            sx={{
                                '& .MuiPaper-root': {
                                    backgroundColor: '#c7d5e0',
                                    color: '#171a21',
                                },
                            }}
                        >
                            <DialogContent color="inherit">
                                <div>
                                    <QuoteCalculator />
                                </div>
                            </DialogContent>
                        </Dialog>
                    </ThemeProvider>
                </div>

                <p className="py-4 text-center">today to get a personalized estimate for your project.</p>

            </section>


            <section className="mx-16 mt-8">
                <h2 className="text-3xl font-bold mb-6">The Benefits of Custom Web Design</h2>
                <div>
                    {CustomdesignProcess.map((Process, index) => (
                        <TransparentAccordion className="my-5"
                            key={index}
                            expanded={expanded === `panel${index}`}
                            onChange={handleChange(`panel${index}`)}
                        >
                            <TransparentAccordionSummary
                                expandIcon={<CustomExpandMoreIcon />}
                                aria-controls={`panel${index}bh-content`}
                                id={`panel${index}bh-header`}
                                aria-label={`Toggle ${Process.Process} details`}
                            >
                                <p className="font-bold"><span className="text-5xl text-[#C13E39]">{Process.id}. </span><span>{Process.Process}</span>{" "}</p>
                            </TransparentAccordionSummary>
                            <TransparentAccordionDetails>
                                <span>{Process.Description}</span>{" "}
                            </TransparentAccordionDetails>
                        </TransparentAccordion>
                    ))}
                </div>
            </section>


            <section className="mx-16 mt-8">
                <h2 className="text-3xl font-bold mb-6">Custom Web Design vs. Template-Based Design</h2>
                <p className="my-4">
                    Choosing between custom web design and template-based design can be challenging. To help you decide, here's a detailed comparison:
                </p>

            </section>

            <section className='mx-16'>
                <div className="overflow-x-auto">
                    <TableContainer

                        sx={{ marginTop: "20px", marginBottom: "20px", textAlign: "center" }}
                    >
                        <Table aria-label="custom vs template comparison table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <p className='font-bold text-2xl'>
                                            Feature
                                        </p>
                                    </TableCell>
                                    <TableCell>
                                        <p className='font-bold text-2xl'>
                                            Custom Web Design
                                        </p>
                                    </TableCell>
                                    <TableCell>
                                        <p className='font-bold text-2xl'>
                                            Template-Based Design
                                        </p>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell><span className='font-bold'>Uniqueness</span></TableCell>
                                    <TableCell>
                                        <CheckCircleIcon color="success" sx={{ mr: 1 }} />
                                        <span>100% unique design customized for your brand.</span>
                                    </TableCell>
                                    <TableCell>
                                        <CancelIcon color="error" sx={{ mr: 1 }} />
                                        <span> Generic design used by multiple businesses.</span>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><span className='font-bold'>Flexibility</span></TableCell>
                                    <TableCell>
                                        <CheckCircleIcon color="success" sx={{ mr: 1 }} />
                                        <span>Fully customizable to meet your specific needs.</span>
                                    </TableCell>
                                    <TableCell>
                                        <CancelIcon color="error" sx={{ mr: 1 }} />
                                        <span>Limited customization options.</span>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><span className='font-bold'>Performance</span></TableCell>
                                    <TableCell>
                                        <CheckCircleIcon color="success" sx={{ mr: 1 }} />
                                        <span>Optimized for speed, SEO, and scalability.</span>
                                    </TableCell>
                                    <TableCell>
                                        <CancelIcon color="error" sx={{ mr: 1 }} />
                                        <span>May have bloated code, affecting performance.</span>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><span className='font-bold'>Cost</span></TableCell>
                                    <TableCell>
                                        <CheckCircleIcon color="success" sx={{ mr: 1 }} />
                                        <span>Higher initial investment but better ROI.</span>
                                    </TableCell>
                                    <TableCell>
                                        <CancelIcon color="error" sx={{ mr: 1 }} />
                                        <span>Lower upfront cost but limited long-term value.</span>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><span className='font-bold'>Support</span></TableCell>
                                    <TableCell>
                                        <CheckCircleIcon color="success" sx={{ mr: 1 }} />
                                        <span>Dedicated support and maintenance.</span>
                                    </TableCell>
                                    <TableCell>
                                        <CancelIcon color="error" sx={{ mr: 1 }} />
                                        <span>Limited or no support after setup.</span>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </section>


            <section className="mx-16 mt-8">
                <h2 className="text-3xl font-bold mb-6">Our Custom Web Design Process</h2>
                <p className="my-4">
                    <span>At </span>{" "}
                    <span><CompanyName /></span>{" "}
                    <span>, we understand that creating a website that perfectly aligns with your business goals requires careful planning and collaboration. As a professional web design company, we follow a proven, step-by-step process to ensure every aspect of your custom website design services meets your expectations. From discovery and consultation to ongoing support and maintenance, we work with you to craft a high-performance site that drives results and supports your business objectives.</span>{" "}

                </p>
            </section>

            <WhyChoose {...ChooseCustomWebDesign} />


        </div >
    )
}

export default CustomWebDesign