import IconButton from '@mui/material/IconButton';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from '@mui/material';
import CompanyName from './CompanyName';


const navItems = [
    { name: "Home", path: "/", titleName: " | Professional Web Design Agency" },
    { name: "Projects", path: "/Projects", titleName: " | Previous and Current Custom Web Design Projects" },
    { name: "Testimonial", path: "/Testimonial", titleName: " | Client testimonials of previous Web Design Expressions services" },
    { name: "Contact", path: "/Contact", titleName: " | Web Design Expressions" },
];


const Navigation = ({ isDarkMode, toggleDarkMode, isOpen, setIsOpen }) => {

    return (
        <nav role="navigation" aria-label="Main Navigation">
            <section className="flex justify-between items-center p-4 print:hidden">
                {/* Company Name (Left Side) */}
                <article className="font-bold lg:text-3xl ">
                    <h2><Link href='/' style={{ color: 'inherit', textDecoration: 'none' }}><CompanyName /></Link></h2>
                </article>

                {/* Navigation Links and Dark Mode Button (Right Side) */}
                <article className="flex items-center space-x-4 ">
                    {/* Navigation Links */}
                    <div className="hidden md:flex space-x-4 text-xl">
                        {navItems.map((item) => (
                            <Link
                                key={item.name}
                                href={item.path}
                                title={`${item.name} ${item.titleName}`}
                                className={`${isDarkMode ? 'text-[#c7d5e0]' : 'text-[#171a21]'}`}
                                aria-label={`Go to ${item.name}`}
                                style={{ color: "inherit", textDecoration: "none", position: "relative", overflow: "hidden", cursor: "pointer" }}
                            >
                                {item.name}
                            </Link>
                        ))}


                    </div>

                    {/* Dark Mode Toggle */}
                    <IconButton
                        onClick={toggleDarkMode}
                        color="inherit"
                        title={isDarkMode ? "Light Mode" : "Dark Mode"}
                        aria-label={isDarkMode ? "Switch to Light Mode" : "Switch to Dark Mode"}
                    >
                        {isDarkMode ?
                            <LightModeIcon style={{ color: '#D14009' }} /> :
                            <DarkModeIcon style={{ color: '#F6F1D5' }} />
                        }
                    </IconButton>

                    {/* Mobile Menu Toggle */}
                    <div className="md:hidden">
                        <IconButton
                            onClick={() => setIsOpen(prev => !prev)}
                            style={{ color: isDarkMode ? '#F6F1D5' : '#171a21' }}
                            aria-label="Toggle Menu"
                        >
                            <MenuIcon />
                        </IconButton>
                    </div>
                </article>
            </section>

            {/* Mobile Menu */}
            {isOpen && (
                <section className="md:hidden mt-4  pl-8">
                    {navItems.map((item) => (
                        <Link
                            key={item.name}
                            href={item.path}
                            title={`${item.name} ${item.titleName}`}
                            className={`block ${isDarkMode ? 'text-[#c7d5e0]' : 'text-[#171a21]'}py-2`}
                            aria-label={`Go to ${item.name}`}
                            style={{ color: "inherit", textDecoration: "none", position: "relative", overflow: "hidden", cursor: "pointer", fontWeight: 'bold' }}
                        >
                            {item.name}
                        </Link>
                    ))}


                </section>


            )}



        </nav>
    );
};

export default Navigation;