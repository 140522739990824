import React, { useState, useCallback, useMemo } from 'react';
import { doc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { ThemeProvider, createTheme } from '@mui/material';
import { MenuItem, Select } from "@mui/material";
import KeyIcon from '@mui/icons-material/Key';
import PageHeader from '../Component/PageHeader';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

const typeExplanations = {
    "Short-tail": "1-2 words long - High search volume, high competition",
    "Long-tail": "3+ words, more specific phrases - Lower search volume but higher conversion rate",
    "Geo-targeting": "Location-based terms - Target specific geographical areas",
    "Customer-defining": "Target specific audience segments - Include industry or customer type",
    "Intent-based": "Navigational (searching for specific site/brand) - Informational (seeking information) - Commercial (researching to buy)",
    "Question": "Based on user questions - Often start with who, what, where, when, why, how",
    "Product-specific": "Focus on specific products/services - Include features or specifications",
    "Seasonal": "Time-sensitive terms - Related to events or seasons",
    "Branded": "Include company/brand names - Target brand-aware customers",
    "LSI": "(Latent Semantic Indexing) Related terms and synonyms - Help search engines understand context",
};

const SuggestedPages = {
    "webdesignexpressions.net": {
        "Home": "/Home",
        "Projects": "/Projects",
        "Testimonial": "/Testimonial",
        "Custom Web Design": "/CustomWebDesign",
        "Website Redesign": "/Redesign",
        "Search Engine Optimization": "/searchEngineOptimization",
        "Web Development": "/CustomWebDevelopment",
        "E-commerce Solutions": "/Ecommerce",
        "UI/UX Design": "/UIUXDesign",
        "Contact Us": "/Contact",
        "Page Speed": "/PageSpeed",
    },
    "bostonairportlimos.com": {
        "Home": "/Home",
        "Services": "/Services",
        "Fleet": "/Fleet",
        "Rates": "/Rates",
        "Reservations": "/Reservations",
        "Areas Served": "/AreasServed",
        "Contact": "/Contact",
    },
    "driverlicenseprep.com": {
        "Home": "/Home",
        "Practice Tests": "/PracticeTests",
        "Study Guide": "/StudyGuide",
        "DMV Locations": "/Locations",
        "FAQ": "/FAQ",
        "Contact": "/Contact",
    },
    "chartergoinfissioncabo.com": {
        "Home": "/Home",
        "Fishing Packages": "/Packages",
        "Contact": "/Contact",
    },
    "allyachtregistries.com": {
        "Home": "/Home",

    }
};

const domains = [
    { value: "Select an Account", label: "Select an Account" },
    { value: "webdesignexpressions.net", label: "webdesignexpressions.net" },
    { value: "bostonairportlimos.com", label: "bostonairportlimos.com" },
    { value: "driverlicenseprep.com", label: "driverlicenseprep.com" },
    { value: "chartergoinfissioncabo.com", label: "chartergoinfissioncabo.com" },
    { value: "forhajj.com", label: "forhajj.com" },
    { value: "allyachtregistries.com", label: "allyachtregistries.com" }
];

const theme = createTheme({
    palette: {
        primary: {
            main: "#99aab5",
        },
        secondary: {
            main: "#C13E39",
        },
    },
});

const initialKeywordState = {
    keyword: "",
    type: "",
    score: 0,
    volume: 0,
    cost: 0.00,
    competition: "",
    Impressions: 0,
    note: "",
    suggestedpage: ""
};

const KeywordHub = () => {
    const [selectedKeyword, setSelectedKeyword] = useState("");
    const [selectedPage, setSelectedPage] = useState("");
    const [selectedDomain, setSelectedDomain] = useState("Select an Account");
    const [newKeyword, setNewKeyword] = useState(initialKeywordState);

    const availablePages = useMemo(() => {
        return selectedDomain !== "Select an Account"
            ? SuggestedPages[selectedDomain] || {}
            : {};
    }, [selectedDomain]);

    const handleKeywordTypeChange = useCallback((event) => {
        const selectedType = event.target.value;
        setSelectedKeyword(selectedType);
        setNewKeyword(prev => ({ ...prev, type: selectedType }));
    }, []);

    const handleSuggestedPageChange = useCallback((event) => {
        const selectedPage = event.target.value;
        setSelectedPage(selectedPage);
        setNewKeyword(prev => ({ ...prev, suggestedpage: selectedPage }));
    }, []);

    const handleDomainChange = useCallback((event) => {
        const newDomain = event.target.value;
        setSelectedDomain(newDomain);
        // Reset selected page when domain changes
        setSelectedPage("");
        setNewKeyword(prev => ({ ...prev, suggestedpage: "" }));
    }, []);

    const resetForm = useCallback(() => {
        setNewKeyword(initialKeywordState);
        setSelectedKeyword("");
        setSelectedPage("");
    }, []);

    const handleSaveKeyword = async () => {
        if (selectedDomain === "Select an Account") {
            alert("Please select a domain first");
            return;
        }

        try {
            const domainRef = doc(db, "domains", selectedDomain);
            const domainSnap = await getDoc(domainRef);

            if (domainSnap.exists()) {
                const keywordData = {
                    keyword: newKeyword.keyword,
                    type: newKeyword.type,
                    score: Number(newKeyword.score) || 0,
                    volume: Number(newKeyword.volume) || 0,
                    cost: Number(newKeyword.cost) || 0,
                    competition: newKeyword.competition,
                    impressions: Number(newKeyword.Impressions) || 0,
                    note: newKeyword.note,
                    suggestedpage: newKeyword.suggestedpage,
                };

                await updateDoc(domainRef, {
                    keywords: arrayUnion(keywordData)
                });

                alert("Keyword saved successfully!");
                resetForm();
            } else {
                alert("Selected domain does not exist!");
            }
        } catch (error) {
            console.error("Error saving keyword:", error);
            alert("Failed to save keyword. Please try again.");
        }
    };

    const handleFieldChange = useCallback((field, value) => {
        setNewKeyword(prev => ({ ...prev, [field]: value }));
    }, []);

    return (
        <div className='mx-16'>
            <div><PageHeader pageTitle="Add Keyword" /></div>

            <div className='my-4'>
                <Select
                    value={selectedDomain}
                    onChange={handleDomainChange}
                    displayEmpty
                    fullWidth
                >
                    {domains.map(domain => (
                        <MenuItem key={domain.value} value={domain.value}>
                            {domain.label}
                        </MenuItem>
                    ))}
                </Select>
            </div>

            <div className='lg:flex gap-4'>
                <div className='my-4 lg:w-6/12'>
                    <TextField
                        label="Keyword"
                        value={newKeyword.keyword}
                        onChange={(e) => handleFieldChange('keyword', e.target.value)}
                        fullWidth
                    />
                </div>
                <div className='my-4 lg:w-6/12'>
                    <Select
                        value={selectedKeyword}
                        onChange={handleKeywordTypeChange}
                        displayEmpty
                        fullWidth
                    >
                        <MenuItem value="" disabled>Keyword Type</MenuItem>
                        {Object.keys(typeExplanations).map((type) => (
                            <MenuItem key={type} value={type}>{type}</MenuItem>
                        ))}
                    </Select>
                </div>
            </div>

            <div className='lg:flex gap-4'>
                <div className='my-4 lg:w-3/12'>
                    <TextField
                        label="Search Volume"
                        value={newKeyword.volume}
                        onChange={(e) => handleFieldChange('volume', e.target.value)}
                        fullWidth
                        type="number"
                    />
                </div>
                <div className='my-4 lg:w-3/12'>
                    <TextField
                        label="Google Cost"
                        value={newKeyword.cost}
                        onChange={(e) => handleFieldChange('cost', e.target.value)}
                        fullWidth
                        type="number"
                    />
                </div>
                <div className='my-4 lg:w-3/12'>
                    <TextField
                        label="Competition"
                        value={newKeyword.competition}
                        onChange={(e) => handleFieldChange('competition', e.target.value)}
                        fullWidth
                    />
                </div>
                <div className='my-4 lg:w-3/12'>
                    <TextField
                        label="Impressions"
                        value={newKeyword.Impressions}
                        onChange={(e) => handleFieldChange('Impressions', e.target.value)}
                        fullWidth
                        type="number"
                    />
                </div>
            </div>

            <div className='lg:flex lg:gap-4'>
                <div className='my-4 lg:w-6/12'>
                    <TextField
                        label="Note"
                        value={newKeyword.note}
                        onChange={(e) => handleFieldChange('note', e.target.value)}
                        fullWidth
                    />
                </div>
                <div className='my-4 lg:w-6/12'>
                    <Select
                        value={selectedPage}
                        onChange={handleSuggestedPageChange}
                        displayEmpty
                        fullWidth
                        disabled={selectedDomain === "Select an Account"}
                    >
                        <MenuItem value="" disabled>Select a Page</MenuItem>
                        {Object.entries(availablePages).map(([pageName, pageUrl]) => (
                            <MenuItem key={pageName} value={pageName}>
                                {pageName}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </div>

            <div>
                <div className='lg:w-6/12 mx-auto'>
                    <ThemeProvider theme={theme}>
                        <Button
                            onClick={handleSaveKeyword}
                            fullWidth
                            size='large'
                            variant="contained"
                            color="secondary"
                            endIcon={<KeyIcon />}
                            disabled={selectedDomain === "Select an Account"}
                        >
                            <b>Save Keyword</b>
                        </Button>
                    </ThemeProvider>
                </div>
            </div>
        </div>
    );
};

export default KeywordHub;