import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import PageHeader from './Component/PageHeader';
import CompanyName from './Component/CompanyName';
import NeedRedesign from './Images/WebsiteRedesign.webp'
import RedesignServices from './Component/RedesignServices';
import WhyChoose from './Component/WhyChoose';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import QuoteCalculator from './Component/Quote';
import SpeedIcon from '@mui/icons-material/Speed'; // For PageSpeed Insights
import SecurityIcon from '@mui/icons-material/Security'; // For Safe Browsing
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';

const theme = createTheme({
    palette: {
        primary: {
            main: "#99aab5", // custom primary color
        },
        secondary: {
            main: "#C13E39", // custom secondary color
        },
    },
});

const ChooseWebsiteRedesign = {
    service: "Website Redesign",
    companyName: "YourCompany",
    introText: "Is your website outdated, slow, or failing to engage visitors? Our website redesign services breathe new life into your online presence. As a trusted redesign agency, we transform your existing site into a modern, high-performing platform that captivates users and boosts your business growth.",
    benefits: [
        {
            title: "Modernized Design & Functionality",
            description: "Our website redesign experts deliver fresh, contemporary designs that prioritize user experience. With fast-loading pages, intuitive navigation, and mobile responsiveness, your revamped website will leave a lasting impression."
        },
        {
            title: "Enhanced SEO Performance",
            description: "Redesigning your website isn't just about looks. We optimize your site's structure, content, and performance to ensure higher search engine rankings and increased organic traffic."
        },
        {
            title: "Custom Designed for Your Brand",
            description: "Your website should be as unique as your business. Our website redesign services reflect your brand's identity while addressing your target audience's needs and behaviors."
        },
        {
            title: "Seamless Transition with Zero Downtime",
            description: "We manage the entire redesign process with precision, ensuring no downtime or disruption to your online operations. Enjoy a hassle-free upgrade to a superior website."
        },
        {
            title: "Improved Conversion Rates",
            description: "A redesign isn't complete without a focus on results. We integrate proven strategies to boost visitor engagement and conversions, driving measurable success for your business."
        }
    ],
    finalHeader: "Elevate Your Website, Elevate Your Business",
    finalText: "Partner with us to revamp your website and unlock its full potential. Contact us today to explore how our redesign services can transform your site into a business powerhouse."
};

const RedesignProcess = [
    {
        id: 1,
        Process: "Initial Consultation and Website Audit",
        Description: "We kick off with an in-depth consultation to understand your business objectives and analyze your current website. This includes a comprehensive audit focusing on site structure, mobile responsiveness, user experience (UX), SEO, and overall performance. Our goal is to identify key areas for improvement and create a strategic plan for your website redesign."
    },
    {
        id: 2,
        Process: "Custom Design and Wireframing",
        Description: "We craft wireframes that map out the structure and layout of your redesigned website. This step ensures your vision aligns with the final design while prioritizing a user-friendly experience and modern aesthetics. Our designs emphasize affordability without compromising on quality or functionality."
    },
    {
        id: 3,
        Process: "Website Development and Testing",
        Description: "Our skilled developers bring the design to life, creating a fast, secure, and fully responsive website. We rigorously test the site on multiple browsers and devices to ensure optimal performance, speed, and seamless navigation for your audience."
    },
    {
        id: 4,
        Process: "SEO and Content Enhancement",
        Description: "To maximize your online visibility, we optimize your content for search engines by integrating relevant keywords like 'affordable website redesign' and 'custom web design.' From enhancing metadata to improving internal linking, we ensure your site is primed to rank higher in search results while providing valuable content for your target audience."
    },
    {
        id: 5,
        Process: "Website Launch and Ongoing Support",
        Description: "After a thorough review, we launch your newly redesigned website. But our work doesn't stop there—we offer ongoing support to address updates, resolve bugs, and make adjustments as needed to keep your site performing at its best."
    },

]


// Custom styled components
const TransparentAccordion = styled(Accordion)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1f2937' : 'transparent',
    color: theme.palette.mode === 'dark' ? '#ffffff' : 'inherit',
}));

const TransparentAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1f2937' : 'transparent',
    color: theme.palette.mode === 'dark' ? '#ffffff' : 'inherit',
}));

const TransparentAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1f2937' : 'transparent',
    color: theme.palette.mode === 'dark' ? '#ffffff' : 'inherit',
}));

const CustomExpandMoreIcon = styled(ExpandMoreIcon)(({ theme }) => ({
    color: theme.palette.mode === 'dark' ? '#F13030' : '#F13030',
}));

const Redesign = () => {

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const currentYear = new Date().getFullYear();

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <PageHeader pageTitle="Affordable Website Redesign Services" />
            <Helmet prioritizeSeoTags>
                <title>Affordable Website Redesign, Custom Redesign Services</title>
                <meta data-react-helmet="true" name="description" content="Boost your website's ranking with affordable search engine optimization services. Enhance your online presence and grow your business today!" />
                <meta data-react-helmet="true" name="keywords" content="website redesign agency, small business website redesign, affordable website redesign, professional website redesign, website redesign specialists, website redesign cost, website redesign services" />
                <meta data-react-helmet="true" property="og:title" content="Affordable Website Redesign, Custom Redesign Services" />
                <meta data-react-helmet="true" property="og:type" content="business" />
                <meta data-react-helmet="true" property="og:url" content="https://webdesignexpressions.net/Redesign" />
                <meta data-react-helmet="true" property="og:image" content="https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/OGRedesign.jpg?alt=media&token=4b99b093-e637-47ce-8fd1-60317335467d" />
                <meta data-react-helmet="true" property="og:description" content="Boost your website's ranking with affordable search engine optimization services. Enhance your online presence and grow your business today!" />
            </Helmet>
            <section>

                <section className='mx-16'>
                    <div>
                        <h2 className='text-3xl font-bold pb-8'>Your Website Might Need a Redesign</h2>
                    </div>
                    <p>
                        An updated website is essential to ensure your business stands out. If your website feels outdated or isn't functioning optimally, it might be time for a website redesign. Whether you're a small business owner or an established brand, we offer affordable website redesign services that focus on improving the design, user experience (UX), performance and <Link to="/SearchEngineOptimization" className="underline" title='Search engine optimization services'>Optimized for Search engines</Link>. Our expert team specializes in crafting professional, custom website redesigns that reflect your brand's identity and cater to your unique needs.
                    </p>
                    <p className='py-4'>
                        <span>At </span>{" "}
                        <span><CompanyName /></span>{" "}
                        <span>, we understand that a website redesign is more than just a fresh look. It's about creating an intuitive and user-friendly experience, optimizing for mobile devices, enhancing SEO, and ensuring your site loads faster. All of these factors contribute to better user engagement, improved search rankings, and ultimately, higher conversion rates. Let us help you boost your online presence with a professional website redesign that meets the demands of </span>{" "}
                        <span>{currentYear} </span>{" "}
                        <span>and beyond.</span>{" "}

                    </p>
                    <p>
                        <span>Whether you need a complete overhaul or a simple refresh, we offer affordable website redesign solutions customized to meet your needs. Our expert team ensures that your new website will not only look great but also perform well on all devices and be optimized for better search engine rankings. Check out our </span>{" "}
                        <span><Link to="/Projects" className="underline" title='custom website redesign Services'>Portfolio of successful projects</Link> </span>{" "}
                        <span>and read what our clients say in our </span>{" "}
                        <span><Link to="/Testimonial" className="underline" title='Testimonials from both previous and current customers'>Testimonials</Link></span>{" "}
                        <span>.</span>{" "}

                    </p>
                </section>

                <figure className='my-8'>
                    <img className='mx-auto' alt='Top view of a team collaborating to assemble gears, symbolizing unity and coordination in web redesign.' src={NeedRedesign} title='Affordable Website Redesign Services, small business website redesign' />
                    <figcaption className="text-center text-sm text-gray-600 mt-2">
                        <span>Our team of experts collaborate to provide exceptional custom web redesign services to meet your business needs.</span>
                    </figcaption>
                </figure>

                <section className='mx-16'>
                    <h2 className="text-2xl font-bold pb-4">Why You Need a Website Redesign</h2>
                    <p className="mb-4">
                        <span>Your website serves as your business's digital storefront. In </span>{" "}
                        <span>{currentYear}</span>{" "}
                        <span>, having a site that is not only visually appealing but also performs seamlessly is crucial. A website that is outdated, difficult to navigate, or doesn't perform well on mobile devices can harm your brand's credibility and turn potential customers away.</span>{" "}

                    </p>
                    <p className="mb-4">
                        <span>We specialize in small business website redesigns, helping entrepreneurs and companies in </span>{" "}
                        <span>{currentYear} </span>{" "}
                        <span>refresh their online presence. From aesthetic improvements to functionality enhancements, our redesign process ensures that your site serves your business goals effectively while standing out in the competitive digital marketplace.</span>{" "}

                    </p>
                </section>

                {/* Test Your Website Section */}
                <section className='mx-16 mt-10'>
                    <h2 className='text-2xl font-bold pb-4'>Test Your Website</h2>
                    <p>
                        Ensure your website is performing at its best with these free tools:
                    </p>
                    <div className="lg:flex gap-4 my-4">
                        <section className='lg:w-4/12 my-4 mx-auto'>
                            <ThemeProvider theme={theme}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                    size='large'
                                    style={{ color: 'inherit' }}
                                    startIcon={<SpeedIcon />}
                                    to="https://pagespeed.web.dev/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <b>Test Speed with PageSpeed Insights</b>
                                </Button>
                            </ThemeProvider>
                        </section>
                        <section className='lg:w-4/12 my-4 mx-auto'>
                            <ThemeProvider theme={theme}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                    size='large'
                                    style={{ color: 'inherit' }}
                                    startIcon={<SecurityIcon />}
                                    to="https://transparencyreport.google.com/safe-browsing/search?hl=en"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <b>Check Security with Safe Browsing</b>
                                </Button>
                            </ThemeProvider>
                        </section>
                        <section className='lg:w-4/12 my-4 mx-auto'>
                            <ThemeProvider theme={theme}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                    size='large'
                                    style={{ color: 'inherit' }}
                                    startIcon={<SearchIcon />}
                                    to="https://search.google.com/test/rich-results"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <b>Test Rich Results</b>
                                </Button>
                            </ThemeProvider>
                        </section>
                    </div>
                </section>

                {/* How Much Does Website Redesign Cost? Section */}
                <section className='mx-16 mt-10'>
                    <h2 className='text-2xl font-bold pb-4'>How Much Does Website Redesign Cost?</h2>
                    <p>
                        The cost of a website redesign varies depending on the complexity of your project, the features you need, and the size of your website. We offer affordable pricing designed for small businesses and entrepreneurs. Use our Request a Quote tool below to get an estimate for your project.
                    </p>
                    <div className="lg:w-1/2 mx-auto my-8" role="button">
                        <ThemeProvider theme={theme}>
                            <Button fullWidth variant="contained" id="btnSubmit" color="secondary" style={{ color: 'inherit' }} size="large" title="Request a Quote" onClick={handleOpen}>
                                <RequestQuoteIcon />&nbsp;&nbsp;<b>Request a Quote</b>
                            </Button>
                        </ThemeProvider>

                        <ThemeProvider theme={theme}>
                            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm" color="inherit"
                                elevation="24"
                                sx={{
                                    '& .MuiPaper-root': {
                                        backgroundColor: '#c7d5e0',
                                        color: '#171a21',
                                    },
                                }}
                            >
                                <DialogContent color="inherit">
                                    <div>
                                        <QuoteCalculator />
                                    </div>
                                </DialogContent>
                            </Dialog>
                        </ThemeProvider>
                    </div>
                </section>

                <section className='mx-16'>
                    <h2 className='text-2xl font-bold py-4'>What We Offer in Our Website Redesign Services</h2>
                    <p>
                        We offer a range of website redesign services that cover all aspects of creating a high-performing, modern website. Whether you're looking to improve the user experience, streamline navigation, or optimize your site for search engines, we have the expertise to deliver results.
                    </p>
                </section>

                <section className='mx-16 mt-10'>
                    <RedesignServices />
                </section>

                <section className='mx-16 mt-10'>
                    <h2 className='text-2xl font-bold pb-4'>Our Affordable Website Redesign Process</h2>
                    <p>
                        <span>At </span>{" "}
                        <span><CompanyName /></span>{" "}
                        <span>, we believe in making the website redesign process as seamless as possible for our clients. Our process is designed to ensure that every aspect of your website is optimized for </span>{" "}
                        <span>{currentYear}</span>{" "}
                        <span> and beyond.</span>{" "}

                    </p>

                    {RedesignProcess.map((Process, index) => (
                        <TransparentAccordion className='my-5'
                            key={index}
                            expanded={expanded === `panel${index}`}
                            onChange={handleChange(`panel${index}`)}
                        >
                            <TransparentAccordionSummary
                                expandIcon={<CustomExpandMoreIcon />}
                                aria-controls={`panel${index}bh-content`}
                                id={`panel${index}bh-header`}
                            >
                                <div className="font-bold"><h2><span className='text-5xl text-[#C13E39]'>{Process.id}. </span><span>{Process.Process}</span>{" "}</h2></div>
                            </TransparentAccordionSummary>
                            <TransparentAccordionDetails>
                                <span>{Process.Description}</span>{" "}
                            </TransparentAccordionDetails>
                        </TransparentAccordion>
                    ))}
                </section>




            </section>
            <WhyChoose {...ChooseWebsiteRedesign} />
        </div >
    )
}

export default Redesign