import React from 'react';
import { Divider } from '@mui/material'
import SocialMedia from './socialMedia';

const PageHeader = ({ pageTitle }) => {
    return (
        <header className="mx-16">
            <div className="lg:flex pt-8">
                <article className="lg:w-10/12 lg:my-0 my-2">
                    <h1 className="text-5xl font-bold">{pageTitle}</h1>
                </article>
                <aside className="lg:w-2/12 lg:my-0 my-2">
                    <div className="lg:float-right lg:text-right">
                        <SocialMedia />
                    </div>
                </aside>
            </div>
            <div className="py-4">
                <Divider />
            </div>
        </header>
    );
};


export default PageHeader