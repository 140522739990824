import PageHeader from './Component/PageHeader';
import CompanyName from './Component/CompanyName';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import WhyChoose from './Component/WhyChoose';
import { Helmet } from 'react-helmet-async';
import WebsiteSecurityImage from './Images/WebsiteSecurity.webp'

const ChooseWebsiteSecurity = {
    service: "Website Security",
    companyName: "YourCompany",
    introText: "Looking for a website security solution that protects your business from modern cyber threats? Our professional website security services ensure your online presence is safeguarded, reliable, and ready to perform in today’s digital landscape. As a leading provider of secure web solutions, we offer tailored protection for businesses of all sizes.",
    benefits: [
        {
            title: "Advanced Encryption Standards",
            description: "Our services include enterprise-grade SSL certificates to secure data transmission and boost customer trust. Protect sensitive information while enhancing your search engine rankings with visible security indicators."
        },
        {
            title: "Secure Hosting Infrastructure",
            description: "We host websites on state-of-the-art servers with robust security measures such as 24/7 monitoring, DDoS protection, automated backups, and physical data center security."
        },
        {
            title: "Comprehensive Threat Protection",
            description: "We safeguard your site against common vulnerabilities, including SQL injections, XSS attacks, brute force attempts, and file upload exploits, ensuring uninterrupted operations and peace of mind."
        },
        {
            title: "Proactive Security Maintenance",
            description: "Our ongoing services include regular vulnerability assessments, malware scanning, and security patch management to stay ahead of potential threats and keep your website optimized."
        },
        {
            title: "Tailored Solutions for Your Business",
            description: "Every business is unique, and so are its security needs. We provide customized website protection strategies designed to address the specific risks and challenges of your industry."
        },
        {
            title: "Customer Trust and Compliance",
            description: "Building trust with your customers starts with a secure online experience. Our solutions help ensure compliance with industry standards and regulations, protecting your reputation and customer data."
        },
        {
            title: "Expert Support You Can Rely On",
            description: "Partner with a team committed to your success. We provide responsive support and guidance, ensuring your website stays secure and functional at all times."
        }
    ],
    finalHeader: "Ready to Protect Your Digital Future",
    finalText: "Choosing us means investing in a secure, worry-free online presence. Contact us today to discuss how our website security services can protect your business, build customer trust, and keep your website performing at its peak. Let us help you safeguard your success in the USA and beyond."
};

const WebsiteSecurity = () => {
    const currentYear = new Date().getFullYear();
    return (
        <article>




            <Helmet prioritizeSeoTags>
                <title>Website Security Solutions | Web Design Expressions</title>
                <meta
                    data-react-helmet="true"
                    name="description"
                    content="Protect your online presence. professional web design solutions featuring SSL encryption, secure hosting, advanced threat protection."
                />
                <meta
                    data-react-helmet="true"
                    property="og:title"
                    content="Website Security Solutions | Web Design Expressions"
                />
                <meta
                    data-react-helmet="true"
                    property="og:type"
                    content="business"
                />
                <meta
                    data-react-helmet="true"
                    property="og:url"
                    content="https://webdesignexpressions.net/WebsiteSecurity"
                />
                <meta
                    data-react-helmet="true"
                    property="og:image"
                    content="https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/OGWebsiteSecurity.jpg?alt=media&token=72a51fda-45dd-4f59-9049-c94f58f2be37"
                />
                <meta
                    data-react-helmet="true"
                    property="og:description"
                    content="Protect your online presence. professional web design solutions featuring SSL encryption, secure hosting, advanced threat protection."
                />
                <meta
                    data-react-helmet="true"
                    name="keywords"
                    content="website security, secure web design, professional web design, SSL encryption, secure hosting, website protection, cyber threats, custom web design solutions, web design agency USA"
                />
            </Helmet>
            <PageHeader pageTitle="Website Security" />

            <section className='mx-16'>
                <h2 className="text-3xl font-bold pb-4">Securing Your Business's Digital Future:</h2>
                <h2 className="text-xl font-bold pb-8"><span>Professional Web Security Solutions by</span>{" "} <CompanyName />{" "}</h2>
                <p>
                    <span>Your business website isn't just a digital storefront—it's the foundation of your online presence. As a professional web design company committed to excellence,</span>{" "}<span>{CompanyName}</span>{" "}{" "}<span>delivers custom web design solutions that prioritize both security and performance. Our affordable, professional custom web design services ensure your business stays protected against evolving cyber threats in</span> {" "}<span>{currentYear}</span>{" "}<span>and beyond.</span>{" "}
                </p>
                <h2 className="text-3xl font-bold py-4">Why Website Security Matters for Your Business</h2>
                <p>
                    As cyber threats become increasingly sophisticated, having a secure website isn't just an option—it's essential for business survival. Our business web design company understands that a single security breach can damage your reputation, compromise customer data, and result in significant financial losses. That's why security is at the core of every custom website we create.
                </p>
                <h2 className="text-3xl font-bold py-4">Comprehensive Security Features in Every Custom Website</h2>
                <p>
                    As your trusted professional web design agency, we implement multiple layers of security protection:
                </p>
            </section>

            <section className='my-12'>
                <figure>
                    <img className='mx-auto' alt='Professional ensuring website security at a workstation indoors' src={WebsiteSecurityImage} title='Professional Website Security Solutions' />
                    <figcaption className='text-center mt-2 text-sm text-gray-600'>Protect your website with comprehensive security solutions</figcaption>
                </figure>
            </section>

            <section className='mx-16'>
                <h2 className="text-3xl font-bold py-4">Advanced SSL Security Certificates</h2>
                <section className='lg:flex lg:gap-8'>
                    <div className='lg:w-2/10 p-2'>
                        <div className='text-center pb-2'><HttpsOutlinedIcon fontSize='large' /></div>
                        <div className='text-center'><span>Connection is Secure</span>{" "}</div>
                    </div>
                    <div className='lg:w-10/12'>
                        <span>
                            Every website we design includes enterprise-grade SSL encryption, ensuring all data transmitted between your website and your customers remains private and secure. This critical security measure not only protects sensitive information but also boosts your search engine rankings and builds customer trust through the visible "secure" indicator in browsers.
                        </span>{" "}
                    </div>
                </section>
                <h2 className="text-3xl font-bold py-4">Secure Hosting Infrastructure</h2>
                <p>
                    Our custom web design services include hosting on state-of-the-art servers with:
                </p>
                <List aria-label="Secure hosting features">
                    <ListItem disablePadding>
                        <ListItemText
                            primary="24/7 monitoring and threat detection"
                            primaryTypographyProps={{ fontWeight: 'bold' }}
                        />
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                        <ListItemText
                            primary="Regular security patches and updates"
                            primaryTypographyProps={{ fontWeight: 'bold' }}
                        />
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                        <ListItemText
                            primary="Automated backup systems"
                            primaryTypographyProps={{ fontWeight: 'bold' }}
                        />
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                        <ListItemText
                            primary="DDoS protection"
                            primaryTypographyProps={{ fontWeight: 'bold' }}
                        />
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                        <ListItemText
                            primary="Physical data center security"
                            primaryTypographyProps={{ fontWeight: 'bold' }}
                        />
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                        <ListItemText
                            primary="Advanced firewall protection"
                            primaryTypographyProps={{ fontWeight: 'bold' }}
                        />
                    </ListItem>
                </List>
                <h2 className="text-3xl font-bold py-4">Protection Against Common Attack Vectors</h2>
                <p>
                    Our professional web design company implements robust security measures to protect against:
                </p>
                <List aria-label="Common attack vectors">
                    <ListItem disablePadding>
                        <ListItemText
                            primary="SQL injection attempts"
                            primaryTypographyProps={{ fontWeight: 'bold' }}
                        />
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                        <ListItemText
                            primary="Cross-site scripting (XSS)"
                            primaryTypographyProps={{ fontWeight: 'bold' }}
                        />
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                        <ListItemText
                            primary="Cross-site request forgery (CSRF)"
                            primaryTypographyProps={{ fontWeight: 'bold' }}
                        />
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                        <ListItemText
                            primary="URL injection attacks"
                            primaryTypographyProps={{ fontWeight: 'bold' }}
                        />
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                        <ListItemText
                            primary="Brute force login attempts"
                            primaryTypographyProps={{ fontWeight: 'bold' }}
                        />
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                        <ListItemText
                            primary="File upload vulnerabilities"
                            primaryTypographyProps={{ fontWeight: 'bold' }}
                        />
                    </ListItem>
                </List>
                <h2 className="text-3xl font-bold py-4">Regular Security Audits and Updates</h2>
                <p>
                    As your business web design company, we don't just build and forget. Our ongoing security maintenance includes:
                </p>
                <List aria-label="Security audits and updates">
                    <ListItem disablePadding>
                        <ListItemText
                            primary="Regular vulnerability assessments"
                            primaryTypographyProps={{ fontWeight: 'bold' }}
                        />
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                        <ListItemText
                            primary="Security patch management"
                            primaryTypographyProps={{ fontWeight: 'bold' }}
                        />
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                        <ListItemText
                            primary="Performance monitoring"
                            primaryTypographyProps={{ fontWeight: 'bold' }}
                        />
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                        <ListItemText
                            primary="Malware scanning"
                            primaryTypographyProps={{ fontWeight: 'bold' }}
                        />
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                        <ListItemText
                            primary="Traffic analysis for suspicious activity"
                            primaryTypographyProps={{ fontWeight: 'bold' }}
                        />
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                        <ListItemText
                            primary="Security certificate management"
                            primaryTypographyProps={{ fontWeight: 'bold' }}
                        />
                    </ListItem>
                </List>
            </section>

            <WhyChoose {...ChooseWebsiteSecurity} />

        </article >
    );
};

export default WebsiteSecurity;