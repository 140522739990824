import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { collection, getDocs, query, where, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase/firebase';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

const TaxTransactionsView = () => {
	const [transactions, setTransactions] = useState([]);
	const [selectedYear, setSelectedYear] = useState('2024');
	const [selectedBusinessType, setSelectedBusinessType] = useState('all');
	const [loading, setLoading] = useState(false);
	const [editDialog, setEditDialog] = useState(false);
	const [editingTransaction, setEditingTransaction] = useState(null);
	const [summary, setSummary] = useState({
		totalIncome: 0,
		totalExpenses: 0,
		netIncome: 0,
		categoryTotals: {},
		businessTypeTotals: {
			personal: { income: 0, expenses: 0 },
			business: { income: 0, expenses: 0 }
		}
	});

	const years = ['2024'];

	useEffect(() => {
		fetchTransactions();
	}, [selectedYear, selectedBusinessType]);

	const calculateSummary = (transactions) => {
		const summary = {
			totalIncome: 0,
			totalExpenses: 0,
			netIncome: 0,
			categoryTotals: {},
			businessTypeTotals: {
				personal: { income: 0, expenses: 0 },
				business: { income: 0, expenses: 0 }
			}
		};

		transactions.forEach(transaction => {
			const amount = Number(transaction.amount);

			// Update total income/expenses
			if (transaction.type === 'income') {
				summary.totalIncome += amount;
				summary.businessTypeTotals[transaction.businessType].income += amount;
			} else {
				summary.totalExpenses += amount;
				summary.businessTypeTotals[transaction.businessType].expenses += amount;
			}

			// Update category totals
			if (!summary.categoryTotals[transaction.category]) {
				summary.categoryTotals[transaction.category] = {
					total: 0,
					count: 0,
					type: transaction.type
				};
			}
			summary.categoryTotals[transaction.category].total += amount;
			summary.categoryTotals[transaction.category].count += 1;
		});

		summary.netIncome = summary.totalIncome - summary.totalExpenses;
		return summary;
	};

	const fetchTransactions = async () => {
		setLoading(true);
		try {
			const yearCollection = collection(db, 'transactions', selectedYear, 'entries');
			let q = yearCollection;

			if (selectedBusinessType !== 'all') {
				q = query(yearCollection, where('businessType', '==', selectedBusinessType));
			}

			const querySnapshot = await getDocs(q);
			const fetchedTransactions = [];
			querySnapshot.forEach((doc) => {
				fetchedTransactions.push({ id: doc.id, ...doc.data() });
			});

			// Sort by date
			fetchedTransactions.sort((a, b) => new Date(b.date) - new Date(a.date));
			setTransactions(fetchedTransactions);

			// Calculate summary
			const newSummary = calculateSummary(fetchedTransactions);
			setSummary(newSummary);
		} catch (error) {
			console.error('Error fetching transactions:', error);
		} finally {
			setLoading(false);
		}
	};

	const formatCurrency = (amount) => {
		return new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD'
		}).format(amount);
	};

	const formatDate = (dateString) => {
		return new Date(dateString).toLocaleDateString('en-US', {
			year: 'numeric',
			month: 'short',
			day: 'numeric'
		});
	};

	const handleEdit = (transaction) => {
		setEditingTransaction(transaction);
		setEditDialog(true);
	};

	const handleDelete = async (transaction) => {
		if (window.confirm('Are you sure you want to delete this transaction?')) {
			try {
				const transactionRef = doc(db, 'transactions', selectedYear, 'entries', transaction.id);
				await deleteDoc(transactionRef);
				await fetchTransactions();
			} catch (error) {
				console.error('Error deleting transaction:', error);
			}
		}
	};

	const handleEditSubmit = async () => {
		try {
			const transactionRef = doc(db, 'transactions', selectedYear, 'entries', editingTransaction.id);
			await updateDoc(transactionRef, {
				amount: Number(editingTransaction.amount),
				category: editingTransaction.category,
				date: editingTransaction.date,
				description: editingTransaction.description,
				payee: editingTransaction.payee,
				type: editingTransaction.type,
				businessType: editingTransaction.businessType
			});
			setEditDialog(false);
			await fetchTransactions();
		} catch (error) {
			console.error('Error updating transaction:', error);
		}
	};

	const handleEditChange = (field, value) => {
		setEditingTransaction(prev => ({
			...prev,
			[field]: value
		}));
	};

	return (
		<div className="space-y-6">
			<div className='mx-16'>
				<div className="text-5xl font-bold pb-4">
					{years.map(year => (
						<div key={year}>{year} Taxes</div>
					))}

				</div>
				<Card sx={{
					boxShadow: 3,
					borderRadius: 2,
					padding: 0,
					backgroundColor: 'transparent',
					backdropFilter: 'blur(10px)',
					border: '1px solid rgba(0, 0, 0, 0.2)',
					marginBottom: 4
				}}>
					<CardContent>
						<div className="lg:flex gap-4 mb-6 print:hidden">
							<div className="lg:w-6/12">
								<InputLabel htmlFor="yearFilter">Year</InputLabel>
								<Select
									id="yearFilter"
									value={selectedYear}
									onChange={(e) => setSelectedYear(e.target.value)}
									fullWidth
								>
									{years.map(year => (
										<MenuItem key={year} value={year}>{year}</MenuItem>
									))}
								</Select>
							</div>
							<div className="lg:w-6/12">
								<InputLabel htmlFor="businessTypeFilter">Business Type</InputLabel>
								<Select
									id="businessTypeFilter"
									value={selectedBusinessType}
									onChange={(e) => setSelectedBusinessType(e.target.value)}
									fullWidth
								>
									<MenuItem value="all">All</MenuItem>
									<MenuItem value="personal">Personal</MenuItem>
									<MenuItem value="business">Business</MenuItem>
								</Select>
							</div>
						</div>

						{/* Summary Section */}
						<div className="mb-6">
							<Card variant="outlined" sx={{ marginBottom: 2 }}>
								<CardContent>
									<div className="grid grid-cols-3 gap-4">
										<div>
											<h3 className="text-lg font-semibold text-green-600">Total Income</h3>
											<p className="text-2xl">{formatCurrency(summary.totalIncome)}</p>
										</div>
										<div>
											<h3 className="text-lg font-semibold text-red-600">Total Expenses</h3>
											<p className="text-2xl">{formatCurrency(summary.totalExpenses)}</p>
										</div>
										<div>
											<h3 className="text-lg font-semibold">Net Income</h3>
											<p className="text-2xl" style={{ color: summary.netIncome >= 0 ? 'green' : 'red' }}>
												{formatCurrency(summary.netIncome)}
											</p>
										</div>
									</div>
								</CardContent>
							</Card>

							{/* Business Type Summary */}
							<Card variant="outlined" sx={{ marginBottom: 2 }}>
								<CardContent>
									<h3 className="text-lg font-semibold mb-3">Business Type Summary</h3>
									<div className="grid grid-cols-2 gap-4">
										<div>
											<h4 className="font-medium">Personal</h4>
											<p>Income: {formatCurrency(summary.businessTypeTotals.personal.income)}</p>
											<p>Expenses: {formatCurrency(summary.businessTypeTotals.personal.expenses)}</p>
											<p>Net: {formatCurrency(summary.businessTypeTotals.personal.income - summary.businessTypeTotals.personal.expenses)}</p>
										</div>
										<div>
											<h4 className="font-medium">Business</h4>
											<p>Income: {formatCurrency(summary.businessTypeTotals.business.income)}</p>
											<p>Expenses: {formatCurrency(summary.businessTypeTotals.business.expenses)}</p>
											<p>Net: {formatCurrency(summary.businessTypeTotals.business.income - summary.businessTypeTotals.business.expenses)}</p>
										</div>
									</div>
								</CardContent>
							</Card>

							{/* Category Summary */}
							<Card variant="outlined">
								<CardContent>
									<h3 className="text-lg font-semibold mb-3">Category Summary</h3>
									<TableContainer>
										<Table size="small">
											<TableHead>
												<TableRow>
													<TableCell>Category</TableCell>
													<TableCell>Type</TableCell>
													<TableCell align="right">Total Amount</TableCell>
													<TableCell align="right">Count</TableCell>
													<TableCell align="right">Average</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{Object.entries(summary.categoryTotals).map(([category, data]) => (
													<TableRow key={category}>
														<TableCell>{category}</TableCell>
														<TableCell>{data.type}</TableCell>
														<TableCell align="right" sx={{
															color: data.type === 'expense' ? 'red' : 'green'
														}}>
															{formatCurrency(data.total)}
														</TableCell>
														<TableCell align="right">{data.count}</TableCell>
														<TableCell align="right">
															{formatCurrency(data.total / data.count)}
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								</CardContent>
							</Card>
						</div>

						{/* Transactions Table */}
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Date</TableCell>
										<TableCell>Type</TableCell>
										<TableCell>Business Type</TableCell>
										<TableCell>Payee</TableCell>
										<TableCell>Category</TableCell>
										<TableCell align="right">Amount</TableCell>
										<TableCell>Description</TableCell>
										<TableCell>Actions</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{loading ? (
										<TableRow>
											<TableCell colSpan={8} align="center">Loading...</TableCell>
										</TableRow>
									) : transactions.length === 0 ? (
										<TableRow>
											<TableCell colSpan={8} align="center">No transactions found</TableCell>
										</TableRow>
									) : (
										transactions.map((transaction) => (
											<TableRow key={transaction.id}>
												<TableCell>{transaction.date}</TableCell>
												<TableCell>{transaction.type}</TableCell>
												<TableCell>{transaction.businessType}</TableCell>
												<TableCell>{transaction.payee}</TableCell>
												<TableCell>{transaction.category}</TableCell>
												<TableCell align="right" sx={{
													color: transaction.type === 'expense' ? 'red' : 'green'
												}}>
													{formatCurrency(transaction.amount)}
												</TableCell>
												<TableCell>{transaction.description}</TableCell>
												<TableCell>
													<IconButton
														size="small"
														onClick={() => handleEdit(transaction)}
													>
														📝
													</IconButton>
													<IconButton
														size="small"
														onClick={() => handleDelete(transaction)}
													>
														🗑️
													</IconButton>
												</TableCell>
											</TableRow>
										))
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</CardContent>
				</Card>

				<Dialog open={editDialog} onClose={() => setEditDialog(false)}>
					<DialogTitle>Edit Transaction</DialogTitle>
					<DialogContent>
						<div className="space-y-4 mt-4">
							<TextField
								fullWidth
								label="Date"
								type="date"
								value={editingTransaction?.date || ''}
								onChange={(e) => handleEditChange('date', e.target.value)}
								InputLabelProps={{ shrink: true }}
							/>
							<TextField
								fullWidth
								label="Amount"
								type="number"
								value={editingTransaction?.amount || ''}
								onChange={(e) => handleEditChange('amount', e.target.value)}
							/>
							<TextField
								fullWidth
								label="Payee"
								value={editingTransaction?.payee || ''}
								onChange={(e) => handleEditChange('payee', e.target.value)}
							/>
							<TextField
								fullWidth
								label="Category"
								value={editingTransaction?.category || ''}
								onChange={(e) => handleEditChange('category', e.target.value)}
							/>
							<TextField
								fullWidth
								label="Description"
								value={editingTransaction?.description || ''}
								onChange={(e) => handleEditChange('description', e.target.value)}
							/>
							<Select
								fullWidth
								value={editingTransaction?.type || 'expense'}
								onChange={(e) => handleEditChange('type', e.target.value)}
								label="Type"
							>
								<MenuItem value="expense">Expense</MenuItem>
								<MenuItem value="income">Income</MenuItem>
							</Select>
							<Select
								fullWidth
								value={editingTransaction?.businessType || 'personal'}
								onChange={(e) => handleEditChange('businessType', e.target.value)}
								label="Business Type"
							>
								<MenuItem value="personal">Personal</MenuItem>
								<MenuItem value="business">Business</MenuItem>
							</Select>
						</div>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setEditDialog(false)}>Cancel</Button>
						<Button onClick={handleEditSubmit} variant="contained">Save</Button>
					</DialogActions>
				</Dialog>
			</div>
		</div>
	);
};

export default TaxTransactionsView;