import React from 'react'
import PreviousProjects from './Portfolio/PreviousProjects';
import Offer from './offer';
import { Helmet } from 'react-helmet-async';
import './App.css';
import Hero from './Component/Hero';
import WeOffersList from './Component/Offers';



export default function Home() {

    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>Professional Web Design Agency, Web Design Company in USA</title>
                <meta data-react-helmet="true" name="description" content="Affordable professional web design agency in the USA offering custom web design, website redesign, and development services." />
                <meta data-react-helmet="true" name="keywords" content="Web Design Expressions, professional web design agency, professional web design company, web design and development company, usa, affordable web design services" />
                <meta data-react-helmet="true" property="og:title" content="Professional Web Design Agency, Affordable Web Design Company USA" />
                <meta data-react-helmet="true" property="og:type" content="website" />
                <meta data-react-helmet="true" property="og:url" content="https://webdesignexpressions.net/" />
                <meta data-react-helmet="true" property="og:image" content="https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/OGHome.jpg?alt=media&token=1c970487-7310-4cec-b41c-31e24c51d175" />
                <meta data-react-helmet="true" property="og:description" content="Affordable professional web design agency in the USA offering custom web design, website redesign, and development services." />
            </Helmet>

            <Hero />

            <WeOffersList />

            <Offer />

            <PreviousProjects />


        </>
    )
}
