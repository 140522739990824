import React, { useState, useEffect } from 'react';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import Alert from '@mui/material/Alert';
import { collection, addDoc, getDocs, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase/firebase'; // Adjust path as needed
import MenuItem from '@mui/material/MenuItem';


const TaxEntryForm = () => {
	const [formData, setFormData] = useState({
		date: '',
		type: 'expense',
		amount: '',
		payee: '',
		category: '',
		description: '',
		businessType: 'personal',
		year: '2024'
	});

	const [error, setError] = useState('');
	const [success, setSuccess] = useState('');
	const [showNewPayee, setShowNewPayee] = useState(false);
	const [newPayeeName, setNewPayeeName] = useState('');

	const years = [
		'2024',
	];

	// Payee database with their default categories
	const [payees, setPayees] = useState({
		expenses: [
			{ name: 'E-ZPass®', defaultCategory: 'Tolls' },
			{ name: 'Fuel 4', defaultCategory: 'Gas' },
			{ name: 'Speedway', defaultCategory: 'Gas' },
			{ name: 'Caton Auto Center Inc', defaultCategory: 'Gas' },
			{ name: 'Carliadaco', defaultCategory: 'Gas' },
			{ name: 'Exxon', defaultCategory: 'Gas' },
			{ name: 'NJ Family Support Center', defaultCategory: 'Child Support' },
			{ name: 'Salman Capital Rent', defaultCategory: 'Rent' },
			{ name: 'Salman Capital Parking', defaultCategory: 'Parking' },
			{ name: 'Verizon', defaultCategory: 'Business Phone' },
			{ name: 'Toyota Financial Services', defaultCategory: 'Car Payment' },
			{ name: 'Across America Insurance', defaultCategory: 'TLC Insurance' },
			{ name: 'New York City', defaultCategory: 'Parking' },
			{ name: 'New York State Dmv', defaultCategory: 'Registration' },
			{ name: 'TLC Driver License Renewal', defaultCategory: 'Registration' },
			{ name: 'BigZ', defaultCategory: 'Car Maintenance' },
			{ name: 'PSEG', defaultCategory: 'Electricity' },
			{ name: 'Panda Express', defaultCategory: 'Restaurant' },
			{ name: 'Mamoun Falafel', defaultCategory: 'Restaurant' },
			{ name: 'Wendy', defaultCategory: 'Restaurant' },
			{ name: 'Popeyes', defaultCategory: 'Restaurant' },
			{ name: 'Burger King', defaultCategory: 'Restaurant' },
			{ name: 'Chipotle', defaultCategory: 'Restaurant' },
			{ name: 'Subway', defaultCategory: 'Restaurant' },
			{ name: '7-Eleven', defaultCategory: 'Restaurant' },
			{ name: 'Wawa', defaultCategory: 'Restaurant' },
			{ name: 'Walmart', defaultCategory: 'Grocery' },
			{ name: 'Many', defaultCategory: 'Grocery' },
			{ name: 'Dime Amy', defaultCategory: 'Grocery' },
			{ name: 'Baraka Halal', defaultCategory: 'Grocery' },
			{ name: 'Brother Produce', defaultCategory: 'Grocery' },
			{ name: 'Unity Beef', defaultCategory: 'Grocery' },
			{ name: 'Credit One', defaultCategory: 'Credit Card Payment' },
			{ name: 'IRS', defaultCategory: 'Standard Deduction' },
		],
		income: [
			{ name: 'Lyft', defaultCategory: 'Lyft Income' },
			{ name: 'Uber', defaultCategory: 'Uber Income' },

		]
	});

	const [categories, setCategories] = useState({
		income: ['Lyft Income', 'Uber Income'],
		expense: ['Tolls', 'Gas', 'Child Support', 'Rent', 'Parking', 'Business Phone', 'Car Payment', 'TLC Insurance', 'Registration', 'Car Maintenance', 'Electricity', 'Restaurant', 'Grocery', 'Credit Card Payment', 'Standard Deduction']
	});

	useEffect(() => {
		const loadTransactions = async () => {
			try {
				const yearDoc = collection(db, 'transactions', formData.year, 'entries');
				const querySnapshot = await getDocs(yearDoc);
				const transactions = [];
				querySnapshot.forEach((doc) => {
					transactions.push({ id: doc.id, ...doc.data() });
				});
			} catch (error) {
				console.error('Error loading transactions:', error);
				setError('Failed to load transactions');
			}
		};

		loadTransactions();
	}, [formData.year]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData(prev => {
			const newData = { ...prev, [name]: value };

			if (name === 'payee' && value) {
				const payeeList = payees[newData.type === 'income' ? 'income' : 'expenses'];
				const selectedPayee = payeeList.find(p => p.name === value);
				if (selectedPayee) {
					newData.category = selectedPayee.defaultCategory;
				}
			}

			return newData;
		});

		setError('');
		setSuccess('');
	};

	const handleAddNewPayee = async () => {
		if (!newPayeeName.trim()) {
			setError('Please enter a payee name');
			return;
		}

		const payeeType = formData.type === 'income' ? 'income' : 'expenses';
		const newPayee = {
			name: newPayeeName,
			defaultCategory: categories[formData.type][0],
			type: payeeType,
			createdAt: serverTimestamp()
		};

		try {
			await addDoc(collection(db, 'payees'), newPayee);

			setPayees(prev => ({
				...prev,
				[payeeType]: [...prev[payeeType], newPayee]
			}));

			setFormData(prev => ({
				...prev,
				payee: newPayeeName,
				category: newPayee.defaultCategory
			}));

			setNewPayeeName('');
			setShowNewPayee(false);
			setSuccess('New payee added successfully!');
		} catch (err) {
			console.error('Error adding payee:', err);
			setError('Failed to add new payee');
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!formData.date || !formData.amount || !formData.payee || !formData.category) {
			setError('Please fill in all required fields');
			return;
		}

		try {
			// Reference to the year-specific subcollection
			const yearCollection = collection(db, 'transactions', formData.year, 'entries');

			await addDoc(yearCollection, {
				...formData,
				amount: Number(formData.amount),
				createdAt: serverTimestamp(),
				updatedAt: serverTimestamp()
			});

			setSuccess(`Transaction saved successfully for year ${formData.year}!`);

			// Clear form after successful save
			setFormData({
				date: '',
				type: 'expense',
				amount: '',
				payee: '',
				category: '',
				description: '',
				businessType: 'personal',
				year: formData.year // Maintain the selected year
			});

		} catch (err) {
			console.error('Error saving transaction:', err);
			setError('Failed to save transaction. Please try again.');
		}
	};

	return (
		<div className="space-y-6">

			<div className='mx-16'>
				<div className="text-5xl font-bold pb-4">Add Transaction</div>
				<div className="mx-auto">
					<Card sx={{
						boxShadow: 3,
						borderRadius: 2,
						padding: 0,
						backgroundColor: 'transparent',
						backdropFilter: 'blur(10px)',
						border: '1px solid rgba(0, 0, 0, 0.2)',
					}}>
						<CardContent className="p-6">
							<form onSubmit={handleSubmit} className="space-y-4">
								{error && (
									<Alert variant="destructive">

										{error}

									</Alert>
								)}

								{success && (
									<Alert className="bg-green-50 text-green-800 border-green-200">
										{success}

									</Alert>
								)}

								<div className="lg:flex gap-4">
									<div className='lg:w-3/12'>
										<InputLabel htmlFor="year">Year *</InputLabel>
										<Select
											id="year"
											name="year"
											value={years}
											defaultValue={years}
											onChange={handleChange}
											className="w-full border rounded"
											required
											disabled
											fullWidth
										>
											{years.map(year => (
												<option key={year} value={year}>{year}</option>
											))}
										</Select>
									</div>
									<div className='lg:w-3/12'>
										<InputLabel htmlFor="date">Date *</InputLabel>
										<TextField
											type="date"
											id="date"
											name="date"
											value={formData.date}
											onChange={handleChange}
											required
											fullWidth
										/>
									</div>
									<div className='lg:w-3/12'>
										<InputLabel htmlFor="type">Type *</InputLabel>
										<Select
											id="type"
											name="type"
											value={formData.type}
											onChange={handleChange}
											className="w-full border rounded"
											required
										>
											<MenuItem value="expense">Expense</MenuItem>
											<MenuItem value="income">Income</MenuItem>
										</Select>
									</div>
									<div className='lg:w-3/12'>

										<InputLabel htmlFor="businessType">Business Type *</InputLabel>
										<Select
											id="businessType"
											name="businessType"
											value={formData.businessType}
											onChange={handleChange}
											className="w-full border rounded"
											required
										>
											<MenuItem value="personal">Personal</MenuItem>
											<MenuItem value="business">Business</MenuItem>
										</Select>
									</div>



								</div>


								<div className='lg:flex gap-4'>
									<div className='lg:w-4/12'>

										<InputLabel htmlFor="payee">Payee *</InputLabel>

										<Select
											id="payee"
											name="payee"
											value={formData.payee}
											onChange={handleChange}
											className="w-full border rounded"
											required
										>
											<MenuItem value="">Select a payee</MenuItem>
											{payees[formData.type === 'income' ? 'income' : 'expenses'].map(p => (
												<MenuItem key={p.name} value={p.name}>{p.name}</MenuItem>
											))}
										</Select>
									</div>
									<div className='lg:w-4/12'>
										<InputLabel htmlFor="category">Category *</InputLabel>
										<Select
											id="category"
											name="category"
											value={formData.category}
											onChange={handleChange}
											className="w-full border rounded"
											required
										>
											<MenuItem value="">Select a category</MenuItem>
											{categories[formData.type].map(cat => (
												<MenuItem key={cat} value={cat}>{cat}</MenuItem>
											))}
										</Select>

									</div>
									<div className='lg:w-4/12'>
										<InputLabel htmlFor="amount">Amount *</InputLabel>
										<TextField
											type="number"
											id="amount"
											name="amount"
											value={formData.amount}
											onChange={handleChange}
											placeholder="0.00"
											step="0.01"
											min="0"
											required
											fullWidth
										/>
									</div>
								</div>

								<div className='lg:w-12/12'>
									<InputLabel htmlFor="description">Description</InputLabel>
									<TextField
										type="text"
										id="description"
										name="description"
										value={formData.description}
										onChange={handleChange}
										placeholder="Enter description (optional)"
										fullWidth
									/>
								</div>


								<div className="flex justify-end space-x-2 pt-4">
									<Button
										type="button"
										variant="outline"
										onClick={() => {
											setFormData({
												date: '',
												type: 'expense',
												amount: '',
												payee: '',
												category: '',
												description: '',
												businessType: 'personal',
												year: formData.year
											});
											setError('');
											setSuccess('');
										}}
									>
										<DeleteIcon className="w-4 h-4 mr-2" />
										Clear
									</Button>
									<Button type="submit">
										<SaveIcon className="w-4 h-4 mr-2" />
										Save Transaction
									</Button>
								</div>
							</form>
						</CardContent>
					</Card>
				</div>
			</div>
		</div>
	);
};

export default TaxEntryForm;