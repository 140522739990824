import React, { useState, useEffect, Suspense } from 'react';
import './App.css';
import StructuredData from './Component/StructuredData';
import Footer from './Component/Footer';
import { Routes, Route, Navigate } from 'react-router-dom'; // Import BrowserRouter
import Navigation from './Component/Navigation';
import Home from './Home';
import Projects from './Portfolio/Projects';
import Testimonial from './Testimonial';
import CustomWebDesign from './CustomWebDesign';
import Redesign from './Redesign';
import SearchEngineOptimization from './SearchEngineOptimization';
import PrivateRoute from "./Component/PrivateRoute";
import CustomWebDevelopment from './CustomWebDevelopment';
import Ecommerce from './Ecommerce';
import UIUXDesign from './UIUXDesign';
import WebsiteSecurity from './WebsiteSecurity';
import PageSpeed from './PageSpeed'
import FAQ from './FAQ';
import PaymentSuccessful from './PaymentSuccessful';
import TermsofUse from './TermsofUse';
import Sitemap from './Sitemap';
import NotFoundPage from './NotFoundPage';
import { HelmetProvider } from 'react-helmet-async';
import { motion } from 'framer-motion';
import Contact from './Contact';
import Login from './login';
import Main from "./Office/Main";
import QR from './Office/QR';
import Invoicing from './Office/Invoicing';
import SEOFactory from './Office/SEOFactory';
import AllAccounts from './Office/AllAccounts';
import AccountDetails from './Office/AccountDetails';
import Keywords from './Office/Keywords';
import KeywordHub from './Office/KeywordHub';
import MetaAdd from './Office/MetaAdd';
import MetaView from './Office/MetaView';
import Taxes from './Office/TaxEntryForm';
import TaxTransactionsView from './Office/TaxTransactionsView';

const pageVariants = {
  initial: { x: "25%", opacity: 0 },
  animate: { x: 0, opacity: 1 },
  exit: { x: "100%", opacity: 0 },
};

function App() {
  const [isDarkMode, setIsDarkMode] = useState(() => localStorage.getItem('darkMode') === 'true');
  const [isOpen, setIsOpen] = useState(false);

  // Toggle dark mode
  const toggleDarkMode = () => {
    setIsDarkMode(prev => !prev);
  };

  // Apply dark mode class to body
  useEffect(() => {
    document.body.classList.toggle('dark-mode', isDarkMode);
    localStorage.setItem('darkMode', isDarkMode);
  }, [isDarkMode]);




  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    // Ensure the app is mounted before rendering helmet
    setIsReady(true);
  }, []);



  return (
    <div>
      {/* Your StructuredData and Navigation components */}
      <StructuredData />
      <Navigation
        isDarkMode={isDarkMode}
        toggleDarkMode={toggleDarkMode}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />

      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={pageVariants}
        transition={{ duration: 0.5, ease: "easeInOut" }}
      >
        {/* Your content that uses Helmet tags goes here */}
        {isReady && (
          // No Route component here anymore
          <div>
            {/* Your content goes here */}
          </div>
        )}
      </motion.div>
      <HelmetProvider>
        <Suspense fallback={null}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Home" element={<Navigate to="/" replace />} />
            <Route path="/Portfolio/Projects" element={<Projects />} />
            <Route path="/Testimonial" element={<Testimonial />} />
            <Route path="/CustomWebDesign" element={<CustomWebDesign />} />
            <Route path="/Redesign" element={<Redesign />} />
            <Route path="/CustomWebDevelopment" element={<CustomWebDevelopment />} />
            <Route path="/SearchEngineOptimization" element={<SearchEngineOptimization />} />
            <Route path="/Ecommerce" element={<Ecommerce />} />
            <Route path="/UIUXDesign" element={<UIUXDesign />} />
            <Route path="/FAQ" element={<FAQ />} />
            <Route path="/Projects" element={<Projects />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/Sitemap" element={<Sitemap />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/PaymentSuccessful" element={<PaymentSuccessful />} />
            <Route path="/WebsiteSecurity" element={<WebsiteSecurity />} />
            <Route path="/PageSpeed" element={<PageSpeed />} />

            {/* Private Routes - Important Change Here */}
            <Route element={<PrivateRoute />}> {/* Wrap private routes with PrivateRoute component */}
              <Route path="/Office/Main" element={<Main />} />
              <Route path="/Office/QR" element={<QR />} />
              <Route path="/Office/Invoicing" element={<Invoicing />} />
              <Route path="/Office/AllAccounts" element={<AllAccounts />} />
              <Route path="/Office/AccountDetails/:id" element={<AccountDetails />} />
              <Route path="/Office/Keywords" element={<Keywords />} />
              <Route path="/Office/KeywordHub" element={<KeywordHub />} />
              <Route path="/Office/MetaAdd" element={<MetaAdd />} />
              <Route path="/Office/MetaView" element={<MetaView />} />
              <Route path="/Office/SEOFactory" element={<SEOFactory />} />
              <Route path="/Office/TaxEntryForm" element={<Taxes />} />
              <Route path="/Office/TaxTransactionsView" element={<TaxTransactionsView />} />

            </Route>

            <Route path="/TermsofUse" element={<TermsofUse />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Suspense>
      </HelmetProvider>
      <div className='mx-16'>
        <Footer />
      </div>

    </div >
  );
}

export default App;