// /src/Office/Main.js

import React from "react";
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ReceiptIcon from '@mui/icons-material/Receipt';
import DomainIcon from '@mui/icons-material/Domain';
import QrCode2Icon from '@mui/icons-material/QrCode2'; import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import FitScreenIcon from '@mui/icons-material/FitScreen';
import { Divider } from "@mui/material";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import CalculateIcon from '@mui/icons-material/Calculate';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

const theme = createTheme({
    palette: {
        primary: {
            main: "#DB324D",
        },
        secondary: {
            main: "#1B7C88",
        },
    },
});

const Main = () => {
    return (
        <div className="mx-16">
            <h1 className="text-3xl font-bold mb-8">Dashboard</h1>

            <section className="lg:flex">
                <div className="lg:w-1/4 text-center px-1 py-2">
                    <ThemeProvider theme={theme}>
                        <Button
                            variant="contained"
                            color="primary"
                            component="a"
                            href="/Office/Invoicing"
                            title=''
                            fullWidth
                            size="large"
                            endIcon={<ReceiptIcon />}
                        >

                            <b>Invoices</b>
                        </Button>
                    </ThemeProvider>

                </div>
                <div className="lg:w-1/4 text-center px-1 py-2">
                    <ThemeProvider theme={theme}>
                        <Button
                            variant="contained"
                            color="primary"
                            component="a"
                            href="/Office/AllAccounts"
                            title=''
                            fullWidth
                            size="large"
                            endIcon={<DomainIcon />}
                        >

                            <b>Domains & Hosting</b>
                        </Button>
                    </ThemeProvider>

                </div>

                <div className="lg:w-1/4 text-center px-1 py-2">
                    <ThemeProvider theme={theme}>
                        <Button
                            variant="contained"
                            color="primary"
                            component="a"
                            href="/Office/AllAccounts"
                            title=''
                            fullWidth
                            size="large"
                            endIcon={<QrCode2Icon />}
                        >

                            <b>QR</b>
                        </Button>
                    </ThemeProvider>

                </div>
            </section>


            <div className="my-4"><Divider /></div>

            <section className="lg:flex">

                <div className="lg:w-1/4 text-center px-1 py-2">
                    <ThemeProvider theme={theme}>
                        <Button
                            variant="contained"
                            color="primary"
                            component="a"
                            href="/Office/KeywordHub"
                            title=''
                            fullWidth
                            size="large"
                            endIcon={<NoteAddIcon />}
                        >

                            <b>Add Keywords</b>
                        </Button>
                    </ThemeProvider>
                </div>
                <div className="lg:w-1/4 text-center px-1 py-2">
                    <ThemeProvider theme={theme}>
                        <Button
                            variant="contained"
                            color="primary"
                            component="a"
                            href="/Office/Keywords"
                            title=''
                            fullWidth
                            size="large"
                            endIcon={<FitScreenIcon />}
                        >

                            <b>View Keywords</b>
                        </Button>
                    </ThemeProvider>
                </div>

                <div className='lg:w-1/4 text-center px-1 py-2'>
                    <ThemeProvider theme={theme}>
                        <Button
                            variant="contained"
                            color="primary"
                            component="a"
                            href="/Office/MetaAdd"
                            title=''
                            fullWidth
                            size="large"
                            endIcon={<NoteAddIcon />}
                        >

                            <b>Add Meta</b>
                        </Button>
                    </ThemeProvider>
                </div>
                <div className='lg:w-1/4 text-center px-1 py-2'>
                    <ThemeProvider theme={theme}>
                        <Button
                            variant="contained"
                            color="primary"
                            component="a"
                            href="/Office/MetaView"
                            title=''
                            fullWidth
                            size="large"
                            endIcon={<FitScreenIcon />}
                        >

                            <b>View Meta</b>
                        </Button>
                    </ThemeProvider>
                </div>

            </section>

            <section className="lg:flex">
                <div className="lg:w-1/4 text-center px-1 py-2">
                    <ThemeProvider theme={theme}>
                        <Button
                            variant="contained"
                            color="primary"
                            component="a"
                            href="/Office/SEOFactory"
                            title=''
                            fullWidth
                            size="large"
                            endIcon={<ScreenSearchDesktopIcon />}
                        >

                            <b>SEO Factory</b>
                        </Button>
                    </ThemeProvider>
                </div>
            </section>
            <div className="my-4"><Divider /></div>

            <section className="lg:flex">
                <div className="lg:w-1/4 text-center px-1 py-2">
                    <ThemeProvider theme={theme}>
                        <Button
                            variant="contained"
                            color="primary"
                            component="a"
                            href="/Office/TaxEntryForm"
                            title=''
                            fullWidth
                            size="large"
                            endIcon={<CalculateIcon />}
                        >

                            <b>Add Taxes</b>
                        </Button>
                    </ThemeProvider>
                </div>

                <div className="lg:w-1/4 text-center px-1 py-2">
                    <ThemeProvider theme={theme}>
                        <Button
                            variant="contained"
                            color="primary"
                            component="a"
                            href="/Office/TaxTransactionsView"
                            title=''
                            fullWidth
                            size="large"
                            endIcon={<LibraryBooksIcon />}
                        >

                            <b>View Taxes</b>
                        </Button>
                    </ThemeProvider>
                </div>
            </section>
        </div>
    );
};

export default Main;
