import PageHeader from './Component/PageHeader'

const currentYear = new Date().getFullYear();

const PageSpeed = () => {
	return (
		<div>
			<PageHeader pageTitle="Page Speed in Web Design" />
			<div className="mx-16">
				<article>
					<section className="pb-8">

						<h2 className="text-3xl font-bold pb-4">Is Your Slow Website Costing You Money?</h2>
						<p>
							<span>Your website might look stunning, but if it's slow, you're losing customers right now. In fact, while you're reading this sentence, potential customers are clicking away from slow websites and choosing faster competitors instead. In today's digital marketplace, speed isn't just a technical metric—it's the cornerstone of your online success.</span>
						</p>
					</section>

					<section className="pb-8">

						<h2 className="text-3xl font-bold pb-4">
							<span>The Hard Truth About Slow Websites in</span> <span>{currentYear}</span>
						</h2>

						<p className="">
							Let's be direct: If your website takes more than 3 seconds to load, you're losing more than half your visitors. That's not just our opinion - Google's research shows 53% of mobile visitors leave websites that take longer than 3 seconds to load. Think about that. Half your potential customers, gone before they even see what you offer.
						</p>

						<p>
							Industry data is clear: businesses with slow websites are losing significant revenue. Major companies like Amazon have found that even a one-second delay in page loading can lead to billions in lost sales annually. While every business is different, the principle remains the same - speed directly impacts your bottom line.
						</p>
					</section>

					<section className="pb-8">
						<h2 className="text-3xl font-bold pb-4">
							The Digital Landscape
						</h2>

						<p>The internet has evolved dramatically, and user expectations have evolved with it:</p>

						<ul className="list-inside list-disc">
							<li className="list-item">5G adoption has made mobile browsing faster than ever</li>
							<li className="list-item">Progressive Web Apps (PWAs) have blurred the line between websites and native apps</li>
							<li className="list-item">Voice search and AI assistants demand near-instant responses</li>
							<li className="list-item">Internet of Things (IoT) devices are accessing websites more than ever</li>
							<li className="list-item">Mobile commerce has become the dominant form of online shopping</li>
						</ul>

						<p>
							In this landscape, a slow website isn't just unfortunate—it's a competitive disadvantage that grows more costly by the day.
						</p>
					</section>

					<section className="pb-8">
						<h2 className="text-3xl font-bold py-2">Understanding Page Speed in Modern Web Design</h2>
						<p>Page speed isn't just about how fast your homepage loads. It's about the complete user experience:</p>


						<div className="lg:flex lg:gap-8 mt-8 text-center">
							<div className="lg:w-1/4">
								<h3 className="text-lg font-bold py-2">Initial Load Time</h3>
								<p>
									<span>The time it takes for the first meaningful content to appear on screen. In</span> <span>{currentYear}</span>, <span>users expect this to happen in under 2 seconds.</span>
								</p>
							</div>
							<div className="lg:w-1/4">
								<h3 className="text-lg font-bold text-center py-2">Interactive Time</h3>
								<p>
									How quickly can users actually interact with your site? Every millisecond of delay reduces engagement.
								</p>
							</div>
							<div className="lg:w-1/4">
								<h3 className="text-lg font-bold text-center py-2">Smooth Scrolling and Navigation</h3>
								<p>
									Users expect buttery-smooth transitions and instant responses to their actions.
								</p>
							</div>
							<div className="lg:w-1/4">
								<h3 className="text-lg font-bold text-center py-2">Resource Loading</h3>
								<p>
									Images, videos, and interactive elements should load seamlessly as users encounter them.
								</p>
							</div>
						</div>
					</section>

					<section>
						<h2 className="text-3xl font-bold pb-4">The Mobile-First Reality</h2>
						<p>
							Mobile traffic now accounts for over 65% of all web visits. This means:
						</p>
						<ul className="list-inside list-disc pt-4">
							<li className="list-item">Your website must perform flawlessly on smartphones and tablets</li>
							<li className="list-item">Mobile users often face variable network conditions</li>
							<li className="list-item">Touch interactions require more responsive interfaces</li>
							<li className="list-item">Mobile users are typically more impatient than desktop users</li>
							<li className="list-item">Search engines prioritize mobile performance in rankings</li>
						</ul>
					</section>

					<section>
						<h2 className="text-3xl font-bold py-4">What Your Customers Aren't Telling You</h2>
						<p>
							Your customers won't complain about your slow website. They won't email you about it. They'll just leave. Here's what happens in real life:
						</p>

						<ul className="list-inside list-disc py-4">
							<li className="list-item">A potential customer searches for your product</li>
							<li className="list-item">They click your link... and wait</li>
							<li className="list-item">While your site loads, they click your competitor's link in another tab</li>
							<li className="list-item">Your competitor's site loads instantly</li>
							<li className="list-item">You've just lost a customer</li>
						</ul>

						<p>
							According to recent studies, just a 1-second delay reduces customer satisfaction by 16%. In {currentYear}, when people can order same-day delivery and stream 4K videos instantly, they expect your website to be lightning fast.
						</p>
					</section>

					<section>
						<h2 className="text-3xl font-bold py-4">The Psychology of Speed</h2>
						<p>
							Research into user behavior shows that slow websites affect more than just convenience:
						</p>
					</section>



				</article>
			</div>
		</div >
	)
}

export default PageSpeed