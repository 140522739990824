import React, { useEffect, useState } from 'react';
import projects from './Projects.json';
import { useSprings, animated } from '@react-spring/web'; // Import react-spring

const PreviousProjects = () => {
    const [shuffledImages, setShuffledImages] = useState([]);

    useEffect(() => {
        const shuffleArray = (array) => {
            const newArray = [...array];
            for (let i = newArray.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
            }
            return newArray;
        };

        const shuffledArray = shuffleArray(projects);
        setShuffledImages(shuffledArray);
    }, []);

    // Create springs for each image with a hover effect
    const [springs, api] = useSprings(shuffledImages.length, index => ({
        transform: 'scale(1)',
        config: { tension: 200, friction: 15 },
    }));

    // Handle mouse enter/leave for each project card
    const handleMouseEnter = (index) => {
        api.start(i =>
            i === index
                ? { transform: 'scale(0.95)' } // Scale up slightly when hovered
                : {}
        );
    };

    const handleMouseLeave = () => {
        api.start({ transform: 'scale(1)' }); // Reset to normal size
    };

    return (
        <section className=''>
            <section className='mx-5'>
                <section className="grid lg:grid-cols-3 grid-cols-1 gap-5">
                    {springs.map((style, index) => {
                        const project = shuffledImages[index];
                        return (
                            <animated.a // Use animated.a for smooth link animation
                                key={project.ID}
                                href={project.ProjectURL}
                                title={project.ProjectName}
                                target='_blank'
                                rel='noreferrer noopener'
                                onMouseEnter={() => handleMouseEnter(index)} // Apply hover effects
                                onMouseLeave={handleMouseLeave}
                                style={style} // Apply spring style
                            >
                                <figure className="relative">
                                    <animated.img
                                        src={project.ProjectImage}
                                        alt={`${project.ProjectName} - business website design services in USA, ${project.ProjectYear}`}
                                        title={`${project.ProjectName} - Professional Web Design Project by Web Design Expressions | Business Website Design Services ${project.ProjectYear}`}
                                        width="960"
                                        height="641"
                                        className="shadow-md mx-auto w-full h-auto sm:max-w-md md:max-w-lg aspect-[960/641]"
                                        style={{
                                            ...style,
                                            objectFit: 'cover',
                                            backgroundColor: 'transparent'
                                        }} // Ensure smooth rendering
                                        loading="lazy"
                                    />
                                </figure>
                            </animated.a>
                        );
                    })}
                </section>
            </section>
        </section>
    );
}

export default PreviousProjects;
