// src/Component/PrivateRoute.js
import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase/firebase'; // Ensure this path is correct

const PrivateRoute = () => {
    const [user, loading] = useAuthState(auth);
    const location = useLocation(); // Get the current location

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!user) {
        return <Navigate to="/Login" state={{ from: location }} replace />; // Redirect if not authenticated
    }

    return <Outlet />; // Render child routes if authenticated
};

export default PrivateRoute;

