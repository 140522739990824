import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import { ThemeProvider, createTheme } from '@mui/material';
import CompanyName from './CompanyName';
import Greeting from './Greeting';
import CustomWebDesign from '../Images/CustomWebDesignoptimization.webp';
import WebsiteRedesign from '../Images/WebsiteRedesignoptimization.webp';
import SearchEngineOptimization from '../Images/SearchEngineOptimizationorganic.webp';
import WebDevelopment from '../Images/CustomWebDevelopment.webp';
import EcommerceImage from '../Images/E-commercesecureSolutions.webp';
import UIUXDesign from '../Images/CreativeUIUXDesign.webp';
import EmailIcon from '@mui/icons-material/Email';
import WebIcon from '@mui/icons-material/Web';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CodeIcon from '@mui/icons-material/Code';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DesignServicesIcon from '@mui/icons-material/DesignServices';




const Offers = [
    {
        OfferImage: CustomWebDesign,
        Offername: 'Custom Web Design',
        OfferDescription: 'Get a high-converting website that drives sales and leads. Our custom designs combine stunning visuals with strategic user experience to turn visitors into customers. Includes mobile optimization and analytics setup.',
        OfferAlt: 'Professional custom web design process showing mobile-responsive layouts and conversion-focused elements',
        OfferTitle: 'Custom Web Design Services | 2X More Conversions | Mobile-Optimized',
        OfferLink: '/CustomWebDesign',
        CTA: 'Get Free Quote',
        OfferIcon: WebIcon,
    },
    {
        OfferImage: WebsiteRedesign,
        Offername: 'Website Redesign',
        OfferDescription: 'Transform your outdated website into a powerful marketing asset. Our redesigns focus on improving conversion rates, user engagement, and search rankings. Includes free migration and 30-day support.',
        OfferAlt: 'Before and after website redesign comparison showing improved user interface and mobile responsiveness',
        OfferTitle: 'Website Redesign Services | Boost Traffic 50%+ | Modern Design',
        OfferLink: '/Redesign',
        CTA: 'Start Redesign',
        OfferIcon: AutoFixHighIcon,
    },
    {
        OfferImage: SearchEngineOptimization,
        Offername: 'Search Engine Optimization',
        OfferDescription: 'Boost your website visibility and attract targeted traffic. Our proven SEO strategies combine technical optimization with content marketing to increase organic rankings. Includes monthly reporting.',
        OfferAlt: 'SEO performance dashboard showing increased website traffic and top search engine rankings',
        OfferTitle: 'SEO Services | Rank Higher in Search engines | Guaranteed Results',
        OfferLink: '/SearchEngineOptimization',
        CTA: 'Free SEO Audit',
        OfferIcon: TrendingUpIcon,
    },
    {
        OfferImage: WebDevelopment,
        Offername: 'Web Development',
        OfferDescription: 'Custom web solutions built for performance and scalability. Our development team creates fast-loading, secure websites that convert visitors into customers. Includes comprehensive testing.',
        OfferAlt: 'Web development process showcasing responsive design and custom functionality implementation',
        OfferTitle: 'Web Development Services | Fast & Secure | Custom Solutions',
        OfferLink: '/CustomWebDevelopment',
        CTA: 'Discuss Project',
        OfferIcon: CodeIcon,
    },
    {
        OfferImage: EcommerceImage,
        Offername: 'E-commerce Solutions',
        OfferDescription: 'Launch your online store with our conversion-optimized e-commerce solutions. Get secure payment processing, inventory management, and marketing tools. Includes performance monitoring.',
        OfferAlt: 'E-commerce platform dashboard showing sales analytics and inventory management system',
        OfferTitle: 'E-commerce Solutions | Increase Sales 3X | Secure Shopping',
        OfferLink: '/Ecommerce',
        CTA: 'Start Selling',
        OfferIcon: ShoppingCartIcon,
    },
    {
        OfferImage: UIUXDesign,
        Offername: 'UI/UX Design',
        OfferDescription: 'Create seamless user experiences that drive engagement and conversions. Our UI/UX design process combines data-driven insights with creative innovation. Includes usability testing.',
        OfferAlt: 'UI/UX design workflow showing user journey mapping and interface prototypes',
        OfferTitle: 'UI/UX Design Services | Boost Engagement 40%+ | User-Centered',
        OfferLink: '/UIUXDesign',
        CTA: 'Start Design',
        OfferIcon: DesignServicesIcon,
    },
];


const theme = createTheme({
    palette: {
        primary: {
            main: "#99aab5", // custom primary color

        },
        secondary: {
            main: "#C13E39", // custom secondary color
        },
    },

});

const WeOffer = ({ offer }) => (
    <div className='lg:w-1/3 m-5'>
        <Card
            sx={{
                boxShadow: 3,
                borderRadius: 2,
                padding: 0,
                backgroundColor: 'transparent',
                backdropFilter: 'blur(10px)',
                border: '1px solid rgba(0, 0, 0, 0.2)',
            }}
        >
            <CardMedia
                component="img"
                // height="140"
                image={offer.OfferImage}
                alt={offer.OfferAlt}
                width="358"
                height="203"
                className='w-full h-auto'
                title={`${offer.OfferTitle}`}
                loading="lazy" // Enable lazy loading

            />
            <div className='border-b-4 border-[#C13E39]'></div>
            <CardContent className="card-content">
                <div className=''>
                    <div className='text-xl pb-4'>
                        <h2>{offer.Offername}</h2>
                    </div>
                </div>

                <p className="offer-description">
                    <span>{offer.OfferDescription}</span>
                </p>
            </CardContent>
            <CardActions>
                <div className="mx-auto mb-5">
                    <ThemeProvider theme={theme}>
                        <Button className="mx-auto" fullWidth variant="contained" id="btnSubmit" color="secondary" style={{ color: 'inherit' }} size='large' title={`${offer.Offername}`} href={offer.OfferLink}>
                            <b>{offer.CTA}</b>&nbsp;&nbsp;{React.createElement(offer.OfferIcon)}
                        </Button>
                    </ThemeProvider>
                </div>

            </CardActions>
        </Card>



    </div>
);

const WeOffersList = () => {
    // Repeat Offers until we have at least 3 items (if needed)
    const repeatOffers = Offers.length >= 3 ? Offers : [...Offers, ...Offers.slice(0, 3 - Offers.length)];

    // Split offers into chunks of 3
    const offerChunks = [];
    for (let i = 0; i < repeatOffers.length; i += 3) {
        offerChunks.push(repeatOffers.slice(i, i + 3));
    }

    return (
        <article className=''>
            <section className='px-10'>
                <h2 className='text-center pb-5 text-3xl font-bold'>What We Offer</h2>
                <p className='pb-4'><Greeting />,</p>
                <p>
                    <span data-translate>At Professional Web Design Agency</span>{" "}
                    <span><CompanyName /></span>{" "}
                    <span>
                        we specialize in creating stunning, high-performance websites tailored to your business needs.
                        As a professional web design agency based in the USA, we focus on delivering affordable,
                        results-driven solutions that help establish your powerful online presence and engage your target audience.
                    </span>{" "}
                </p>
            </section>

            {offerChunks.map((chunk, index) => (
                <section key={index} className='lg:flex lg:flex-row lg:gap-5 text-center lg:w-full'>
                    {chunk.map((offer, index) => (
                        <WeOffer key={index} offer={offer} />
                    ))}
                </section>
            ))}

            <section className='mx-16'>
                <h2 className='text-center py-4 text-3xl font-bold'>What Sets Us Apart:</h2>
                <p className='font-bold'>
                    Here's what makes us your ideal Professional Web Design Agency partner:
                </p>
                <ul className='list-disc'>
                    <li className='list-item py-2 offer-description'>
                        Custom Website Development We create unique designs that reflect your brand identity and business objectives, handling each project with meticulous attention to detail.
                    </li>
                    <li className='list-item py-2 offer-description'>
                        Search Engine Optimization (SEO) Built-in SEO best practices help improve your visibility online and drive organic traffic to your website.
                    </li>
                    <li className='list-item py-2 offer-description'>
                        Ongoing Support & Maintenance Count on our dedicated team for continuous support to keep your website performing at its best.
                    </li>
                </ul>
            </section>

            <section className='mx-16'>
                <p>
                    Contact your Professional Web Design Agency experts today to discuss how we can transform your online presence.
                </p>
            </section>
            <section>
                <p className='text-2xl font-bold text-center py-4'>
                    <span><CompanyName /> </span>{" "}
                    <span>- where quality meets affordability in USA web design.</span>{" "}
                </p>
            </section>

            <div className='my-8 lg:w-1/4 w-1/2 mx-auto'>
                <ThemeProvider theme={theme}>
                    <Button fullWidth variant="contained" id="btnSubmit" color="secondary" style={{ color: 'inherit' }} size='large' aria-label='Contact Web Design Expressions, the Web design and SEO company' title="Contact Web Design Expressions, the Web design and SEO company" href='/Contact'>
                        <EmailIcon />&nbsp;&nbsp;<b>Contact us</b>
                    </Button>
                </ThemeProvider>
            </div>
        </article>
    );
};

export default WeOffersList;
