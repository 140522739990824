import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup"; // For validation
import { TextField, Button } from "@mui/material"; // Import MUI components
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./firebase/firebase"; // Import Firebase auth instance
import { useNavigate } from 'react-router-dom';
import LoginIcon from '@mui/icons-material/Login';


const allowedEmail = "m3li14@gmail.com"; // The only allowed email

const Login = () => {

    const navigate = useNavigate();

    // Formik setup
    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email("Invalid email address")
                .required("Email is required")
                .oneOf([allowedEmail], "This email is not allowed"), // Restrict to the allowed email
            password: Yup.string()
                .min(6, "Password must be at least 6 characters")
                .required("Password is required"),
        }),
        onSubmit: async (values, { setSubmitting, setErrors }) => {
            try {
                // Firebase sign-in
                await signInWithEmailAndPassword(auth, values.email, values.password);
                // alert("Login successful!");
                console.log();
                navigate("/Office/Main"); // Redirect to the protected page after successful login
                console.log();

            } catch (error) {
                setErrors({ firebase: error.message });
            }

            setSubmitting(false);

        },


    });

    return (
        <div className="flex justify-center items-center ">
            <div className="p-8 rounded-lg shadow-sm shadow-[#171a21] dark:shadow-sm dark:shadow-[#ffffff] w-full max-w-md">

                <form onSubmit={formik.handleSubmit}>
                    {/* MUI Email Input */}
                    <div className="mb-4">
                        <TextField
                            id="email"
                            label="Email Address"
                            variant="outlined"
                            fullWidth
                            className="custom-textfield"
                            {...formik.getFieldProps("email")}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                    </div>

                    {/* MUI Password Input */}
                    <div className="mb-4">
                        <TextField
                            id="password"
                            label="Password"
                            type="password"
                            variant="outlined"
                            fullWidth
                            className="custom-textfield"
                            {...formik.getFieldProps("password")}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                        />
                    </div>

                    {/* Submit Button */}
                    <Button
                        type="submit"

                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={formik.isSubmitting}
                        endIcon={<LoginIcon />}
                    >
                        {formik.isSubmitting ? "Logging in..." : "Login"}
                    </Button>

                    {/* Firebase Error Display */}
                    {formik.errors.firebase && (
                        <div className="text-red-500 text-sm mt-2 text-center">{formik.errors.firebase}</div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default Login;
