import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Card, CardContent } from '@mui/material';
import accounts from './Accounts.json';
import PageHeader from '../Component/PageHeader';

// Helper function to format the expiration date (unchanged)
const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
};

const AllAccounts = () => {
    const navigate = useNavigate(); // Use useNavigate instead of useHistory

    // Function to handle sorting by date (unchanged)
    const sortAccounts = (accounts) => {
        return accounts.sort((a, b) => {
            const dateA = new Date(a.expirationDate);
            const dateB = new Date(b.expirationDate);
            return dateA - dateB;
        });
    };

    // Calculate the total value of all accounts (unchanged)
    const totalValue = accounts.reduce((total, account) => {
        const domainPrice = parseFloat(account.domainPrice);
        const hostingPrice = parseFloat(account.hostingPrice);
        const accountTotal = (isNaN(domainPrice) ? 0 : domainPrice) + (isNaN(hostingPrice) ? 0 : hostingPrice);
        return total + accountTotal;
    }, 0);

    const sortedAccounts = sortAccounts(accounts);

    return (
        <div>
            <div><PageHeader pageTitle="Domain & Hosting" /></div>

            <div className="mx-16">
                <div className="my-4">
                    <div className='text-2xl font-bold text-right'>Total Value: ${totalValue.toFixed(2)}</div>
                </div>
                <div className="grid grid-cols-1 gap-4">
                    {sortedAccounts.map((account) => (
                        <Card
                            key={account.id}
                            sx={{
                                boxShadow: 3,
                                borderRadius: 2,
                                padding: 0,
                                backgroundColor: 'transparent',
                                backdropFilter: 'blur(10px)',
                                border: '1px solid rgba(0, 0, 0, 0.2)',
                                cursor: 'pointer',
                            }}
                            onClick={() => navigate(`/Office/AccountDetails/${account.id}`)} // Use navigate()
                        >
                            <CardContent>
                                <div className="font-bold">{account.accountName}</div>
                                <div>Expiration: {formatDate(account.expirationDate)}</div>
                            </CardContent>
                        </Card>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AllAccounts;