import Link from '@mui/material/Link';
import footerLinksData from './links.json';
import { FaChevronRight } from "react-icons/fa";
import { Divider } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material';
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PaymentIcon from '@mui/icons-material/Payment';
import Button from '@mui/material/Button';

const theme = createTheme({
    palette: {
        primary: {
            main: "#99aab5", // custom primary color
        },
        secondary: {
            main: "#C13E39", // custom secondary color
        },
    },
});

const FooterNavigation = () => {
    const customText = (
        <div className="space-y-4">
            <h3 className="font-bold text-xl pb-2">
                <span>Contact</span>
                <div className='my-2'><Divider /></div>
            </h3>

            <div>
                <div className=''>
                    <ThemeProvider theme={theme}>
                        <Button fullWidth variant="contained" id="btnSubmit" color="secondary" title="Call Now for Affordable, Professional Web Design Services" href="tel:9734193293" label=""><PhonelinkRingIcon></PhonelinkRingIcon>&nbsp;&nbsp;<b>Call Now</b> </Button>
                    </ThemeProvider>
                </div>

                <div className='my-8'>
                    <ThemeProvider theme={theme}>
                        <Button fullWidth variant="contained" id="btnSubmit" color="secondary" title="Chat on WhatsApp for Custom Web Design and Development Services" href="https://api.whatsapp.com/send?phone=19734193293" label=""><WhatsAppIcon></WhatsAppIcon>&nbsp;&nbsp;<b>Chat Now</b> </Button>
                    </ThemeProvider>
                </div>

                <div>
                    <ThemeProvider theme={theme}>
                        <Button fullWidth variant="contained" id="btnSubmit" color="secondary" title="Make a Secure Online Payment to Web Design Expressions" href="https://buy.stripe.com/eVa9Ep7wy57b0Ny4gp" label=""><PaymentIcon></PaymentIcon>&nbsp;&nbsp;<b>Make a Payment</b> </Button>
                    </ThemeProvider>
                </div>
            </div>

        </div>
    );

    return (
        <div className="py-12">
            <div className="mx-auto px-4">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                    {/* Render the first three columns */}
                    {Object.entries(footerLinksData).slice(0, 3).map(([columnTitle, links]) => (
                        <div key={columnTitle} className="space-y-4">
                            <h3 className="font-bold text-xl pb-2">
                                <span>{columnTitle}</span>
                                <div className='my-2'><Divider /></div>
                            </h3>
                            <ul className="space-y-2">
                                {links.map((link, index) => (
                                    <li key={index}>
                                        <Link
                                            href={link.url}
                                            className="flex items-center duration-200 group"
                                            title={link.LinkTitle}
                                        >
                                            <FaChevronRight
                                                size={14}
                                                className="mr-2 duration-200"
                                            />
                                            <span className='bg-transparent text-[#0D47A1]'>{link.title}</span>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}

                    {/* Custom text column */}
                    <div>
                        {customText}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FooterNavigation;