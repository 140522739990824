import React, { useState, useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import emailjs from 'emailjs-com';
import { IoMdSend } from 'react-icons/io';
import ReCAPTCHA from 'react-google-recaptcha';

const features = [
    { id: 'contact', label: 'Contact Form', price: 150 },
    { id: 'gallery', label: 'Image Gallery', price: 150 },
    { id: 'blog', label: 'Blog', price: 500 },
    { id: 'ecommerce', label: 'E-commerce', price: 1500 },
    { id: 'booking', label: 'Booking System', price: 800 },
    { id: 'social', label: 'Social Integration', price: 250 },
    { id: 'seo', label: 'SEO', price: 700 },
    { id: 'analytics', label: 'Analytics', price: 200 },
    { id: 'hosting', label: 'Hosting:', price: 260 }
];

const QuoteCalculator = () => {
    const [quoteCalculated, setQuoteCalculated] = useState(false);
    const [total, setTotal] = useState(0);
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const recaptchaRef = useRef(null);

    const formik = useFormik({
        initialValues: {
            pages: '',
            selectedFeatures: [],
            name: '',
            email: '',
            phone: '',
            honeypot: '' // Hidden field to catch bots
        },
        validationSchema: Yup.object({
            pages: Yup.number()
                .required('Required')
                .positive('Must be a positive number')
                .integer('Must be an integer'),
            name: Yup.string().required('Required'),
            email: Yup.string().email('Invalid email address').required('Required'),
            phone: Yup.string().required('Required'),
            honeypot: Yup.string() // Should remain empty
        }),
        onSubmit: (values, { resetForm }) => {
            // Verify reCAPTCHA
            if (!recaptchaValue) {
                alert('Please complete the reCAPTCHA!');
                return; // Stop form submission if reCAPTCHA is not verified
            }

            // Check honeypot field (anti-spam measure)
            if (values.honeypot) {
                console.log('Honeypot detected');
                setQuoteCalculated(true); // Show success message but don't actually send
                resetForm();
                return;
            }

            const pagesCost = values.pages * 100; // e.g., $100 per page
            const featuresCost = values.selectedFeatures.reduce(
                (acc, featureId) => acc + (features.find(f => f.id === featureId)?.price || 0),
                0
            );
            const calculatedTotal = pagesCost + featuresCost;

            setTotal(calculatedTotal);

            // Prepare data to send via email
            const emailData = {
                name: values.name,
                email: values.email,
                phone: values.phone,
                pages: values.pages || null,
                selectedFeatures: values.selectedFeatures.length
                    ? values.selectedFeatures.map(featureId => features.find(f => f.id === featureId)?.label).join(', ')
                    : null,
                totalCost: calculatedTotal ? calculatedTotal.toFixed(2) : null,
                'g-recaptcha-response': recaptchaValue // Include the reCAPTCHA response
            };

            // Send email using emailjs
            emailjs.send(
                'service_jgu8a5r',
                'template_wtutmlg',
                emailData,
                'SqhtzwskGNPLF05Ug'
            )
                .then((result) => {
                    console.log(result.text);
                    setQuoteCalculated(true);
                    resetForm();
                    // Reset reCAPTCHA after successful submission
                    if (recaptchaRef.current) {
                        recaptchaRef.current.reset();
                        setRecaptchaValue(null);
                    }
                }, (error) => {
                    console.log(error.text);
                    alert('Failed to send the quote, please try again.');
                });
        }
    });

    const handleCheckboxChange = (featureId) => {
        const selectedFeatures = formik.values.selectedFeatures.includes(featureId)
            ? formik.values.selectedFeatures.filter(id => id !== featureId)
            : [...formik.values.selectedFeatures, featureId];
        formik.setFieldValue('selectedFeatures', selectedFeatures);
    };

    const handleRecaptcha = (value) => {
        setRecaptchaValue(value);
    };

    return (
        <div>
            <div className="text-3xl font-bold pb-4">Quote Calculator</div>
            <form onSubmit={formik.handleSubmit}>
                <TextField
                    fullWidth
                    id="pages"
                    name="pages"
                    label="Number of Pages"
                    type="number"
                    value={formik.values.pages}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.pages && Boolean(formik.errors.pages)}
                    helperText={formik.touched.pages && formik.errors.pages}
                    margin="dense"
                />

                {/* Honeypot field - hidden from real users but bots might fill it */}
                <TextField
                    sx={{ display: 'none' }}
                    id="honeypot"
                    name="honeypot"
                    value={formik.values.honeypot}
                    onChange={formik.handleChange}
                />

                <div>
                    <div className="text-xl font-bold pt-2">Select Features:</div>
                    <div className='my-2'><Divider /></div>
                </div>

                <Grid container spacing={0}>
                    {features.map(feature => (
                        <Grid item xs={6} sm={4} key={feature.id}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formik.values.selectedFeatures.includes(feature.id)}
                                        onChange={() => handleCheckboxChange(feature.id)}
                                        name={feature.id}
                                    />
                                }
                                label={feature.label}
                            />
                        </Grid>
                    ))}
                </Grid>

                <div>
                    <div className="text-xl font-bold pt-2">Your Contact Information:</div>
                    <div className='my-2'><Divider /></div>
                </div>
                <Grid container spacing={1}>
                    <Grid item xs={6} sm={4}>
                        <TextField
                            fullWidth
                            id="name"
                            name="name"
                            label="Full Name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <TextField
                            fullWidth
                            id="email"
                            name="email"
                            label="Email Address"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <TextField
                            fullWidth
                            id="phone"
                            name="phone"
                            label="Phone Number"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.phone && Boolean(formik.errors.phone)}
                            helperText={formik.touched.phone && formik.errors.phone}
                            margin="normal"
                        />
                    </Grid>
                </Grid>
                <div className="my-4">
                    {/* Google reCAPTCHA with ref */}
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6Ld9B7wqAAAAABRTOeq8uGyaYwZZzls1n-N2xRtp"
                        onChange={handleRecaptcha}
                    />
                </div>
                <Button
                    color="secondary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    sx={{ mt: 2 }}
                    startIcon={<IoMdSend size={20} />}
                    disabled={!recaptchaValue}
                >
                    Submit
                </Button>
            </form>

            {quoteCalculated && (
                <div className='mt-2'>
                    Your quote has been sent successfully!
                </div>
            )}
        </div>
    );
};

export default QuoteCalculator;