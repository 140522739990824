import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import CompanyName from './CompanyName';
import '../App.css';
import FooterNavigation from './FooterNavigation';

const today = new Date();


function Footer() {

    return (
        <div className='mt-5 clear-both'>
            <div className='print:hidden'><FooterNavigation /></div>
            <Divider />

            <section className='py-4'>
                <p><Link href='/Home' style={{ color: 'inherit', textDecoration: 'none', fontWeight: 'bold' }} exact="true">&copy; {today.getFullYear()} - <CompanyName /></Link></p>
            </section>

        </div>
    )
}

export default Footer