import React, { useState, useEffect, useCallback } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase/firebase';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const Keywords = () => {
    const [selectedDomain, setSelectedDomain] = useState('Select an Account');
    const [allKeywords, setAllKeywords] = useState([]);
    const [filteredKeywords, setFilteredKeywords] = useState([]);
    const [search, setSearch] = useState('');
    const [sortConfig, setSortConfig] = useState({ field: 'keyword', direction: 'asc' });
    const [expandedRows, setExpandedRows] = useState(new Set());
    const [editingKeyword, setEditingKeyword] = useState(null);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [keywordToDelete, setKeywordToDelete] = useState(null);

    // Define columns array
    const columns = [
        { label: 'Keyword', field: 'keyword' },
        { label: 'Type', field: 'type' },
        { label: 'Cost', field: 'cost' },
        { label: 'Score', field: 'score' },
        { label: 'Impressions', field: 'impressions' },
        { label: 'Volume', field: 'volume' },
        { label: 'Competition', field: 'competition' },
    ];

    const typeScores = {
        'Short-tail': 10,
        'Long-tail': 7,
        'Branded': 8,
    };

    const competitionScores = {
        High: 3,
        Medium: 5,
        Low: 8,
    };

    const getTrafficScore = (volume) => {
        if (volume > 10000) return 10;
        if (volume > 1000) return 7;
        if (volume > 100) return 5;
        return 3;
    };

    const handleDomainChange = (event) => {
        setSelectedDomain(event.target.value);
    };

    const handleSort = (field) => {
        let direction = 'asc';
        if (sortConfig.field === field && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ field, direction });
    };

    const handleRowExpand = useCallback((id) => {
        setExpandedRows(prev => {
            const newSet = new Set(prev);
            if (newSet.has(id)) {
                newSet.delete(id);
            } else {
                newSet.add(id);
            }
            return newSet;
        });
    }, []);

    const handleEdit = (keyword) => {
        setEditingKeyword({ ...keyword });
        setIsEditDialogOpen(true);
    };

    const handleDelete = (keyword) => {
        setKeywordToDelete(keyword);
        setIsDeleteDialogOpen(true);
    };

    const handleEditSave = async () => {
        try {
            const domainRef = doc(db, 'domains', selectedDomain);
            const domainSnapshot = await getDoc(domainRef);

            if (domainSnapshot.exists()) {
                const data = domainSnapshot.data();
                const updatedKeywords = data.keywords.map(k =>
                    k.keyword === editingKeyword.keyword ? editingKeyword : k
                );

                await updateDoc(domainRef, { keywords: updatedKeywords });

                // Update local state
                setAllKeywords(prev =>
                    prev.map(k => k.keyword === editingKeyword.keyword ? editingKeyword : k)
                );

                setIsEditDialogOpen(false);
                setEditingKeyword(null);
            }
        } catch (error) {
            console.error('Error updating keyword:', error);
        }
    };

    const handleDeleteConfirm = async () => {
        try {
            const domainRef = doc(db, 'domains', selectedDomain);
            const domainSnapshot = await getDoc(domainRef);

            if (domainSnapshot.exists()) {
                const data = domainSnapshot.data();
                const updatedKeywords = data.keywords.filter(k =>
                    k.keyword !== keywordToDelete.keyword
                );

                await updateDoc(domainRef, { keywords: updatedKeywords });

                // Update local state
                setAllKeywords(prev =>
                    prev.filter(k => k.keyword !== keywordToDelete.keyword)
                );

                setIsDeleteDialogOpen(false);
                setKeywordToDelete(null);
            }
        } catch (error) {
            console.error('Error deleting keyword:', error);
        }
    };

    // Memory optimization: Memoize the filtered and sorted keywords
    const processKeywords = useCallback(() => {
        const filtered = allKeywords.filter((keyword) =>
            keyword.keyword.toLowerCase().includes(search.toLowerCase())
        );

        const sorted = [...filtered].sort((a, b) => {
            if (typeof a[sortConfig.field] === 'string') {
                return sortConfig.direction === 'asc'
                    ? a[sortConfig.field].localeCompare(b[sortConfig.field])
                    : b[sortConfig.field].localeCompare(a[sortConfig.field]);
            }
            return sortConfig.direction === 'asc'
                ? a[sortConfig.field] - b[sortConfig.field]
                : b[sortConfig.field] - a[sortConfig.field];
        });

        setFilteredKeywords(sorted);
    }, [allKeywords, search, sortConfig]);

    useEffect(() => {
        processKeywords();
    }, [processKeywords]);

    const fetchKeywords = async (domain) => {
        try {
            const domainRef = doc(db, 'domains', domain);
            const domainSnapshot = await getDoc(domainRef);

            if (domainSnapshot.exists()) {
                const data = domainSnapshot.data();
                if (data.keywords && Array.isArray(data.keywords)) {
                    const fetchedKeywords = data.keywords.map((keyword) => ({
                        keyword: keyword.keyword,
                        type: keyword.type || determineKeywordType(keyword.keyword),
                        score: calculateKeywordScore(keyword),
                        cost: keyword.cost || 0,
                        impressions: keyword.impressions || 0,
                        volume: keyword.volume || 0,
                        competition: keyword.competition || 'Low',
                        suggestedpage: keyword.suggestedpage || '',
                    }));
                    setAllKeywords(fetchedKeywords);
                }
            }
        } catch (err) {
            console.error('Error fetching keywords:', err);
        }
    };

    useEffect(() => {
        if (selectedDomain !== 'Select an Account') {
            fetchKeywords(selectedDomain);
        }
    }, [selectedDomain]);

    const calculateKeywordScore = (keyword) => {
        const { type, volume, competition } = keyword;
        const typeScore = typeScores[type] || 0;
        const trafficScore = getTrafficScore(volume || 0);
        const competitionScore = competitionScores[competition] || 5;
        return ((typeScore + trafficScore + competitionScore) / 3).toFixed(2);
    };

    function determineKeywordType(keyword) {
        // Keep your existing determineKeywordType implementation
        return ['LSI']; // Simplified for example
    }

    const detectAndUpdateType = (keyword) => {
        return determineKeywordType(keyword);
    };

    return (
        <div className="p-6">
            <div className="mb-4">
                <Select
                    value={selectedDomain}
                    onChange={handleDomainChange}
                    displayEmpty
                    className="w-full"
                >
                    <MenuItem value="Select an Account">Select an Account</MenuItem>
                    <MenuItem value="webdesignexpressions.net">Web Design Expressions</MenuItem>
                    <MenuItem value="bostonairportlimos.com">Boston Airport Limos</MenuItem>
                    <MenuItem value="driverlicenseprep.com">driverlicenseprep.com</MenuItem>
                    <MenuItem value="chartergoinfissioncabo.com">chartergoinfissioncabo.com</MenuItem>
                    <MenuItem value="forhajj.com">forhajj.com</MenuItem>
                    <MenuItem value="allyachtregistries.com">allyachtregistries.com</MenuItem>
                </Select>
            </div>

            <div className="print:hidden">
                <TextField
                    label="Search Keywords"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>

            <TableContainer>
                <Table aria-label="keywords table">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={10} style={{ textAlign: 'right' }}>
                                <strong>Total Keywords: </strong> {filteredKeywords.length}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell /> {/* Expand button */}
                            {columns.map((col) => (
                                <TableCell
                                    key={col.field}
                                    onClick={() => col.field && handleSort(col.field)}
                                    className={col.field ? 'cursor-pointer' : ''}
                                >
                                    {col.label}
                                    {col.field && sortConfig.field === col.field && (
                                        sortConfig.direction === 'asc' ? (
                                            <ArrowDropUpIcon fontSize="small" />
                                        ) : (
                                            <ArrowDropDownIcon fontSize="small" />
                                        )
                                    )}
                                </TableCell>
                            ))}
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredKeywords.map((keyword) => (
                            <React.Fragment key={keyword.keyword}>
                                <TableRow>
                                    <TableCell>
                                        <IconButton
                                            size="small"
                                            onClick={() => handleRowExpand(keyword.keyword)}
                                        >
                                            {expandedRows.has(keyword.keyword) ? (
                                                <ExpandLessIcon className="text-[#C13E39]" />
                                            ) : (
                                                <ExpandMoreIcon className="text-[#C13E39]" />
                                            )}
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>{keyword.keyword}</TableCell>
                                    <TableCell>{keyword.type}</TableCell>
                                    <TableCell>
                                        <span className="text-green-700 font-bold">
                                            ${keyword.cost || '-'}
                                        </span>
                                    </TableCell>
                                    <TableCell>{keyword.score}</TableCell>
                                    <TableCell>{keyword.impressions || '-'}</TableCell>
                                    <TableCell>{keyword.volume || '-'}</TableCell>
                                    <TableCell>{keyword.competition || '-'}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            size="small"
                                            onClick={() => handleEdit(keyword)}
                                            className="text-blue-600"
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            size="small"
                                            onClick={() => handleDelete(keyword)}
                                            className="text-red-600"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={10} style={{ paddingBottom: 0, paddingTop: 0 }}>
                                        <Collapse in={expandedRows.has(keyword.keyword)} timeout="auto" unmountOnExit>
                                            <div className="p-4">
                                                <div><strong>Additional Information:</strong></div>
                                                <div><strong>Type:</strong> <span className="text-red-700">{detectAndUpdateType(keyword.keyword).join(', ')}</span></div>
                                                <div>Note: {keyword.note || "No Data"}</div>
                                                <div>Pages: {keyword.suggestedpage || "No Data"}</div>
                                            </div>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Edit Dialog */}
            <Dialog open={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)}>
                <DialogTitle>Edit Keyword</DialogTitle>
                <DialogContent>
                    {editingKeyword && (
                        <div className="space-y-4 mt-4">
                            <TextField
                                fullWidth
                                label="Keyword"
                                value={editingKeyword.keyword}
                                onChange={(e) => setEditingKeyword({
                                    ...editingKeyword,
                                    keyword: e.target.value
                                })}
                            />
                            <TextField
                                fullWidth
                                label="Cost"
                                type="number"
                                value={editingKeyword.cost || ''}
                                onChange={(e) => setEditingKeyword({
                                    ...editingKeyword,
                                    cost: parseFloat(e.target.value)
                                })}
                            />
                            <TextField
                                fullWidth
                                label="Note"
                                value={editingKeyword.note || ''}
                                onChange={(e) => setEditingKeyword({
                                    ...editingKeyword,
                                    note: e.target.value
                                })}
                            />
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsEditDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleEditSave} variant="contained" color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Confirmation Dialog */}
            <Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete this keyword?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleDeleteConfirm} variant="contained" color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Keywords;