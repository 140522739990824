import React, { useMemo } from 'react';

export default function Greeting() {
    const greeting = useMemo(() => {
        const hour = new Date().getHours();

        if (hour >= 5 && hour < 12) return 'Good morning';
        if (hour >= 12 && hour < 18) return 'Good afternoon';
        return 'Good evening';
    }, []);

    return <span className="custom-font font-bold">{greeting}</span>;
}