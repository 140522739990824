import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Divider } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import CompanyName from '../Component/CompanyName';
// import LogoTemp from '../Images/Logo.svg';


const Invoicing = () => {
    const [items, setItems] = useState([]);
    const [description, setDescription] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [price, setPrice] = useState(0);
    const [selectedCompany, setSelectedCompany] = useState('');
    const [invoiceNumber, setInvoiceNumber] = useState('01');
    const [accountNumber, setAccountNumber] = useState('');
    const [attention, setAttention] = useState('');
    const [jobName, setJobName] = useState('');
    // const [amountReceived] = useState(''); // New state for Amount Received
    // const [isAmountReceivedVisible, setIsAmountReceivedVisible] = useState(false); // Control visibility of Amount Received input

    const companies = {
        "All Yacht Registries, Inc.": { accountNumber: "0000-0000", jobName: "allyachtregistries.com", attention: "Lisa Swartz" },
        "Ares Custom Yachts": { accountNumber: "0911-0013", jobName: "arescustomyachts.com", attention: "Rick Pineiro" },
        "National Save The Sea Turtle Foundation - D": { accountNumber: "1103-0015", jobName: "donatetheboattocharity.com", attention: "Anna Bennett" },
        "National Save The Sea Turtle Foundation - S": { accountNumber: "1103-0015", jobName: "savetheseaturtle.org", attention: "Anna Bennett" },
        "For Hajj": { accountNumber: "1401-0044", jobName: "forhajj.com", attention: "Hassan Ahmed" },
        "Goin' Fission": { accountNumber: "2405-0045", jobName: "chartergoinfissioncabo.com", attention: "Andrew Plummer" },
        "Huish Marine Group, Inc.": { accountNumber: "0902-0007", jobName: "huishmarine.com", attention: "Betty Fletcher" },
        "Travel Saver": { accountNumber: "1307-0039", jobName: "travelsaver.us", attention: "Saad Taher" },
        "Yacht Transport Lines, Inc.": { accountNumber: "0912-0014", jobName: "yachttransportlines.com", attention: "Fernando Nogueira" },
        "Sherif Travel": { accountNumber: "1201-0026", jobName: "immigrationland.net", attention: "Saad Taher" },
        "Lay Cornie Academy": { accountNumber: "2409-0046", jobName: "laycornieschools.org", attention: "Lay Cornie" },
        "The Bagdad Chase Mine": { accountNumber: "2411-0047", jobName: "bagdadchaseminingcompany.com", attention: "Andrew Plummer" },


    };

    // const companyDetails = companies[selectedCompany] || {};

    const handleCompanyChange = (e) => {
        const companyName = e.target.value;
        setSelectedCompany(companyName);
        if (companyName) {
            setAccountNumber(companies[companyName].accountNumber);
            setJobName(companies[companyName].jobName);
            setAttention(companies[companyName].attention);
        } else {
            setAccountNumber('');
            setJobName('');
            setAttention('');
        }
    };

    const addItem = () => {
        const newItem = { description, quantity, price };
        setItems([...items, newItem]);
        setDescription('');
        setQuantity(1);
        setPrice(0);
    };

    const deleteItem = (index) => {
        const updatedItems = items.filter((_, i) => i !== index);
        setItems(updatedItems);
    };

    const calculateTotal = () => {
        return items.reduce((total, item) => total + item.quantity * item.price, 0);
    };

    // const totalAfterAmountReceived = () => {
    //     return Math.max(calculateTotal() - (parseFloat(amountReceived) || 0), 0).toFixed(2);
    // };

    return (
        <div className="mx-auto p-2">

            <Helmet prioritizeSeoTags>
                <title>
                    {`${jobName}-${accountNumber}-${invoiceNumber} Invoice`}
                </title>
            </Helmet>

            <div className="border-4 border-[#284761] rounded-xl">
                {/* Header Section */}
                <div className="flex justify-between items-start mb-6 ">
                    <div className="w-5/12 text-center">
                        {/* <div className='text-5xl font-bold py-12 px-8 '><CompanyName /></div> */}
                        {/* <img src="https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/Logo-2.png?alt=media&token=c00f0237-d9e0-40a9-a116-a3db5a68689f" alt="Company Logo" className="mx-auto px-5 pt-5 w-[100%] h-auto" /> */}
                        <div className='w-7/12 mx-auto pt-4'>
                            {/* <img src={LogoTemp} alt='' title='' className='' /> */}
                            {/* <div className='lg:text-5xl text-4xl font-bold py-12 px-8 ' style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}><CompanyName /></div> */}
                            <img src="https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/Logo-2.png?alt=media&token=2db10f43-0ec3-41c5-9b4a-81fe99ff93ee" alt="Web Design Expressions" className="mx-auto w-[100%] h-auto" />

                        </div>





                        {/* <div className='font-bold text-center p-8 text-5xl '>

                            <div className='py-20'><CompanyName /></div>
                        </div> */}
                    </div>
                    <div className="w-7/12 text-right px-6 pt-6">
                        <div className="">
                            <div className="block font-bold print:hidden">Company Name:</div>
                            <select
                                value={selectedCompany}
                                onChange={handleCompanyChange}
                                className="border p-1 w-full print:hidden"
                            >
                                <option value="">Select a company</option>
                                {Object.keys(companies).map((company) => (
                                    <option key={company} value={company}>
                                        {company}
                                    </option>
                                ))}
                            </select>
                            <div className="hidden print:block"><strong>Company Name:</strong> {selectedCompany || 'Select a company'}</div>
                        </div>
                        <div className='my-1'><Divider /></div>
                        <div><strong>Account Number:</strong> {accountNumber || 'Select a company'}</div>
                        <div className='my-1'><Divider /></div>
                        <div><strong>Invoice Number:</strong> {invoiceNumber}
                            <input
                                type="text"
                                value={invoiceNumber}
                                onChange={(e) => setInvoiceNumber(e.target.value)}
                                className="border p-1 w-full print:hidden"
                            />

                        </div>
                        <div className='my-1'><Divider /></div>
                        <div><strong>Date:</strong> {dayjs().format('MMMM DD, YYYY')}</div>
                        <div className='my-1'><Divider /></div>
                        <div><strong>Job Name:</strong> <a href={`https://${jobName || ''}`} target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}>{jobName || 'Select a company'}</a></div>

                    </div>
                </div>

                <div className='px-6'>
                    <h2 className="text-xl font-bold mb-2"><strong>Attention:</strong> {attention || 'N/A'}</h2>
                    <div><Divider /></div>
                </div>


                {/* Item Addition Section */}
                <div className="mb-4 flex print:hidden">
                    <input
                        type="text"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Item Description"
                        className="border p-1 flex-1 mr-2"
                    />
                    <input
                        type="number"
                        value={quantity}
                        onChange={(e) => setQuantity(parseInt(e.target.value))}
                        placeholder="Quantity"
                        className="border p-1 w-20 mr-2"
                    />
                    <input
                        type="number"
                        value={price}
                        onChange={(e) => setPrice(parseFloat(e.target.value))}
                        placeholder="Price"
                        className="border p-1 w-24 mr-2"
                    />
                    <button onClick={addItem} className="bg-blue-500 text-white p-1">Add Item</button>
                </div>

                {/* Invoice Table */}
                <div className="overflow-x-auto px-6 pt-2">
                    <table className="w-full text-left border-collapse">
                        <thead>
                            <tr className="bg-[#c7d5e0]">
                                <th className="border p-1 w-3/4">Job Description </th>

                                <th className="border p-1 w-1/4 text-center">Amount</th>
                                <th className="border p-1 print:hidden">Action</th>
                            </tr>
                        </thead>
                        <tbody className='bg-[#c7d5e0]'>
                            {items.map((item, index) => (
                                <tr key={index} className={index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}>
                                    <td className="border p-1 w-3/4">{item.description}</td>


                                    <td className="border p-1 w-1/4 text-center">${(item.quantity * item.price).toFixed(2)}</td>
                                    <td className="border p-1 text-center print:hidden">
                                        <button
                                            onClick={() => deleteItem(index)}
                                            className="bg-red-500 text-white p-1 rounded"
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}

                            <tr className="">
                                <th className="border p-1 w-3/4 bg-white text-right">Total Amount Due:</th>

                                <th className="border p-1 w-1/4 bg-[#c7d5e0] text-center">${calculateTotal().toFixed(2)}</th>
                                <th className="border p-1 print:hidden">Action</th>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {/* Total Calculation */}


                {/* Footer Section */}

                <footer className="mt-2 px-6">
                    {/* <div className='my-2'><Divider /></div> */}
                    <div className="">
                        <div className='flex pb-5'>
                            <div className='w-1/2'>
                                <div>Please Make Payment to:</div>
                                <div className='text-lg py-1'><b><CompanyName /></b></div>
                                {/* <div>
                                    <address>
                                        351 Broad st B1501<br />
                                        Newark, NJ 07104
                                    </address>
                                </div> */}
                                <div><a href="tel:19734193293" title="call Web Design Expressions">1 973-419-3293</a></div>
                                <div><a href="https://webdesignexpressions.net/" title="Visit webdesignexpressions.net">webdesignexpressions.net</a></div>
                            </div>
                            <div className='w-1/2 lg:py-5 '>
                                <a href="https://buy.stripe.com/eVa9Ep7wy57b0Ny4gp" target="_blank" rel="noopener noreferrer">
                                    <img alt="" class="float-right" src="https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/btn_paynowCC_LG.gif?alt=media&token=e555990a-db64-4f29-9d0e-5e9dd8689075" title="Make a Payment to Web Design Expressions" /></a>
                            </div>
                        </div>

                    </div>
                </footer>
            </div>

            {/* Print Styles */}
            <style jsx global>{`
                @media print {
                    .print\\:hidden {
                        display: none !important;
                    }
                    .print\\:block {
                        display: block !important;
                    }
                }
            `}</style>
        </div>
    );
}

export default Invoicing;
