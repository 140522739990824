import WhyChoose from './Component/WhyChoose';
import PageHeader from './Component/PageHeader';
import CompanyName from './Component/CompanyName';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import UIUXDesignImage from './Images/UIUXDesign.webp';
import { Helmet } from 'react-helmet-async';

const currentYear = new Date().getFullYear();

const Company = "Web Design Expressions"

const ChooseUIUX = {
    service: "UI/UX Design Services",
    introText: `Looking to elevate your website's user experience? At ${Company}, we specialize in crafting intuitive, visually stunning, and engaging digital experiences through expert UI/UX design services. In ${currentYear}, having a user-centric website is more critical than ever. Our tailored UI/UX design solutions ensure your website not only looks great but also delivers exceptional functionality that keeps users coming back.`,
    benefits: [
        {
            title: "User-Centric Design Strategy",
            description: `Our UI/UX design process begins with understanding your audience. Through in-depth user research and strategy, we craft designs that resonate with your users' needs and expectations, creating an engaging and intuitive experience tailored to your brand.`
        },
        {
            title: "Seamless Usability & Navigation",
            description: `Great design isn't just about aesthetics—it's about functionality. Our UX design focuses on ensuring users can navigate your site effortlessly, finding the information they need and completing actions smoothly, boosting engagement and satisfaction.`
        },
        {
            title: "Visually Stunning Interfaces",
            description: `Our UI design expertise ensures every visual element—from colors and typography to layout and animations—is aligned with your brand and optimized for impact. We create beautiful, responsive designs that captivate users across all devices.`
        },
        {
            title: "Accessibility & Inclusivity",
            description: `We prioritize creating websites that are inclusive and accessible to all. By adhering to established accessibility standards, we ensure your site is usable for individuals with diverse needs, building trust and broadening your audience.`
        },
        {
            title: "Data-Driven Optimization",
            description: `Our process doesn’t stop at design. We use analytics, A/B testing, and heatmaps to continuously refine and optimize the user experience, ensuring your website evolves with user preferences and industry trends.`
        },
        {
            title: "Enhanced Engagement & Conversions",
            description: `A well-designed UI/UX keeps users engaged and drives action. Whether it's making a purchase, filling out a form, or exploring your content, our designs are conversion-focused to deliver measurable results for your business.`
        },
        {
            title: "Future-Proof Design Solutions",
            description: `With rapidly evolving digital trends, your website needs to stay ahead. We create scalable, modern designs that adapt to growing user demands and new technologies, ensuring your site remains relevant and effective.`
        }
    ],
    finalHeader: `Transform Your Digital Presence with Expert UI/UX Design`,
    finalText: `Partnering with ${Company} means investing in more than just design—it’s about creating a meaningful, user-focused online experience that sets your brand apart. Ready to enhance engagement, boost conversions, and delight your users? Let’s build a website that works for you!`
};


const UIUX = [
    {
        id: 1,
        Service: 'User Research & Strategy',
        Description: 'Our process starts with understanding your audience. We conduct deep research to uncover user needs and behaviors, crafting a UX strategy tailored to meet their expectations. This ensures a seamless user experience on your website.'
    },
    {
        id: 2,
        Service: 'Wireframing & Prototyping',
        Description: 'Next, we develop wireframes and prototypes, which provide a visual structure of the site. This step allows us to focus on usability, ironing out issues early and aligning with your vision before the final design.'
    },
    {
        id: 3,
        Service: 'Visually Compelling UI Design',
        Description: 'Our UI design aligns with your brand, creating a visually stunning, responsive interface. Every detail, from colors to typography, is crafted to enhance appeal and functionality across all devices.'
    },
    {
        id: 4,
        Service: 'Accessibility & Inclusivity',
        Description: 'We at Web Design Expressions prioritize accessibility, ensuring all users can interact comfortably. By following established standards, we make your website inclusive, from color contrast to screen-reader compatibility.'
    },
    {
        id: 5,
        Service: 'Usability Testing & Iteration',
        Description: 'To perfect your design, we perform usability testing. Using A/B testing, heatmaps, and user feedback, we refine the UI/UX continually, enhancing engagement, reducing bounce rates, and boosting conversions.'
    },
    {
        id: 6,
        Service: 'Data-Driven Improvements',
        Description: 'User preferences evolve, and we keep your site updated. We analyze performance with analytics and feedback, making informed adjustments to keep your website aligned with user needs and trends.'
    }
];


const UIUXDesign = () => {
    return (
        <div>
            <Helmet prioritizeSeoTags>
                <title>Premium UI/UX Design Services | Web Design Expressions</title>
                <meta
                    data-react-helmet="true"
                    name="keywords"
                    content="UI/UX design services, user interface design, user experience design, professional UI/UX solutions, website design, seamless user experience, enhance user engagement, UI/UX experts, Web Design Expressions"
                />
                <meta
                    data-react-helmet="true"
                    name="description"
                    content="Enhance user engagement with professional UI/UX design services. Tailored solutions for seamless navigation and impactful design."
                />
                <meta
                    data-react-helmet="true"
                    property="og:title"
                    content="Premium UI/UX Design Services | Web Design Expressions"
                />
                <meta
                    data-react-helmet="true"
                    property="og:type"
                    content="business"
                />
                <meta
                    data-react-helmet="true"
                    property="og:url"
                    content="https://webdesignexpressions.net/UIUXDesign"
                />
                <meta
                    data-react-helmet="true"
                    property="og:image"
                    content="https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/OGCustomWebDesign.jpg?alt=media&token=ea17b35d-cbad-4a64-82aa-436f90c70a15"
                />
                <meta
                    data-react-helmet="true"
                    property="og:description"
                    content="Enhance user engagement with professional UI/UX design services. Tailored solutions for seamless navigation and impactful design."
                />
            </Helmet>
            <PageHeader pageTitle="UI/UX Design Services" />
            <section className='mx-16'>
                <article>
                    <h2 className='text-2xl font-bold'>Enhance User Engagement with Premium UI/UX Design Services</h2>
                    <p className='py-4'>
                        <span>In today's digital-first world, user experience (UX) and user interface (UI) design play a pivotal role in how visitors perceive and interact with your brand online. At </span>{" "}
                        <span><CompanyName /></span>{" "}
                        <span>, we believe that UI/UX design is more than just aesthetics—it's about creating a seamless and enjoyable journey for every visitor. As part of our What We Offer services, our UI/UX design solutions focus on understanding your users, crafting designs that engage, and ultimately helping your business stand out in a crowded digital landscape.</span>{" "}
                    </p>
                </article>

                <article>
                    <h2 className='text-2xl font-bold'>What is UI/UX Design and Why is it Crucial?</h2>
                    <p className='py-4'>UI/UX design is a blend of visual design, usability, and functionality that ensures your website is not only beautiful but also easy to navigate and highly effective. UI design addresses the visual elements—buttons, layout, colors—while UX design focuses on the overall interaction experience and journey of the user. Together, they make sure that visitors have an enjoyable, efficient, and intuitive experience on your site.</p>
                    <p>A well-designed UI/UX not only captures attention but retains it, making it easy for your audience to find what they need, interact with your content, and ultimately take action, whether it's filling out a form, making a purchase, or engaging with your services.</p>
                </article>

                <article>
                    <h2 className='text-2xl font-bold py-4'>
                        <span>How </span>{" "}
                        <span><CompanyName /> </span>{" "}
                        <span>Approaches UI/UX Design</span>{" "}
                    </h2>
                    <p>
                        <span>At </span>{" "}
                        <span><CompanyName /> </span>{" "}
                        <span>, we go beyond templates and generic designs to craft unique, brand-specific solutions that resonate with your audience. Here's how we ensure your website is designed with precision, purpose, and lasting impact:</span>{" "}
                    </p>
                </article>
            </section>

            <section className='my-12'>
                <figure>
                    <img
                        className='mx-auto'
                        alt='UI/UX Design Services for Enhanced User Engagement'
                        src={UIUXDesignImage}
                        title='UI/UX Design Services to Enhance User Engagement and Drive Conversions'
                    />
                    <figcaption className='text-center mt-2 text-sm text-gray-600'>
                        <p>UI/UX Design Services: Crafting Seamless, Engaging, and User-Centric Digital Experiences</p>{" "}
                    </figcaption>
                </figure>
            </section>

            <section className='mx-16 my-8'>
                <div className='lg:grid lg:grid-cols-3 lg:gap-8'>
                    {UIUX.map(item => (
                        <article key={item.id} className='mb-4'>
                            <Card
                                sx={{
                                    boxShadow: 3,
                                    borderRadius: 2,
                                    padding: 0,
                                    backgroundColor: 'transparent',
                                    backdropFilter: 'blur(10px)',
                                    border: '1px solid rgba(0, 0, 0, 0.2)',
                                }}
                            >
                                <CardContent className="card-content">
                                    <h2 className='text-xl font-bold pb-5'>{item.Service}</h2>
                                    <p>{item.Description}</p>
                                </CardContent>
                            </Card>
                        </article>
                    ))}
                </div>
            </section>

            <section className='mx-16'>
                <article>
                    <h2 className='text-2xl font-bold pb-4'>
                        <span>Transform Your Website with </span>{" "}
                        <span><CompanyName /></span>{" "}
                        <span>'s UI/UX Expertise</span>{" "}
                    </h2>
                    <p className='pb-8'>
                        <span>A user-centric website is essential for standing out in today's competitive online environment. Our team at </span>{" "}
                        <span><CompanyName /> </span>{" "}
                        <span>is dedicated to helping you create a website that is not only visually appealing but intuitive, accessible, and impactful. With our UI/UX design services, we bridge the gap between your brand and your audience, offering them a meaningful experience that leads to increased engagement and conversions.</span>{" "}
                    </p>
                    <p>
                        <span className='font-bold'>Ready to enhance your website's user experience?</span>{" "}
                        <span>Reach out to us today to discuss how our UI/UX design solutions can elevate your brand and help you achieve your digital goals.</span>{" "}
                    </p>
                </article>
            </section>

            <WhyChoose {...ChooseUIUX} />
        </div>
    )
}

export default UIUXDesign